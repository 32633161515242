import { useState, useRef, useEffect, Props } from "react";
import { ColorMusicColor, NoteRGB, NoteTextColor } from "./../Colors";
import {
  partition as d3partition,
  arc as d3arc,
  hierarchy,
  HierarchyRectangularNode,
} from "d3";
import { NoteShape } from "../Shapes";

export interface ColorWheelData {
  name: string;
  symbol: string;
  value?: number;
  shape?: "square" | "circle";
  color?: ColorMusicColor;
  children?: ColorWheelData[];
}

export type ColorWheelNode = HierarchyRectangularNode<ColorWheelData>;

export const ColorWheelNote = ({
  nodeProps = () => {
    // do nothing
    return {};
  },
  data,
  node,
}: {
  data: ColorWheelData;
  node?: ColorWheelNode;
  nodeProps?: (data: ColorWheelData) => React.ComponentPropsWithoutRef<"g">;
}) => {
  const getTextTransform = (node: ColorWheelNode) => {
    const x = (((node.x0 + node.x1) / 2) * 180) / Math.PI;
    const y = (node.y0 + node.y1) / 2;
    return `rotate(${x - 90}) translate(${y},0) rotate(${90})`;
  };

  const getShapeTransform = (node: ColorWheelNode) => {
    switch (NoteShape(node.data.name)) {
      case "circle": {
        return getTextTransform(node);
      }
      case "square": {
        const x = (((node.x0 + node.x1) / 2) * 180) / Math.PI;
        const y = (node.y0 + node.y1) / 2;
        return `rotate(${x - 90}) translate(${y + 28},-28) rotate(${90})`;
      }
    }
  };

  const color = NoteRGB(data.name);
  const textColor = NoteTextColor(data.name);

  const label = (
    <text
      key={`${data.name}-label`}
      transform={node ? getTextTransform(node) : undefined}
      dy="0.35em"
      fill={textColor}
    >
      {data.name}
    </text>
  );

  switch (NoteShape(data.name)) {
    case "circle": {
      return (
        <g {...nodeProps(data)}>
          <circle
            key={`${data.name}-circle`}
            stroke={textColor}
            fill={color}
            transform={node ? getShapeTransform(node) : undefined}
            r="1.25em"
          />
          {label}
        </g>
      );
    }
    case "square": {
      return (
        <g {...nodeProps(data)}>
          <rect
            key={`${data.name}-square`}
            stroke={textColor}
            fill={color}
            transform={node ? getShapeTransform(node) : undefined}
            width={56}
            height={56}
          />
          {label}
        </g>
      );
    }
    default: {
      return label;
    }
  }
};

export const ColorWheel = ({
  data,
  size,
  tonic = "C",
  onSegmentClick = () => {
    // do nothing
  },
  nodeProps = () => {
    // do nothing
    return {};
  },
}: {
  data: ColorWheelData;
  size: number;
  tonic?: string;
  nodeProps?: (data: ColorWheelData) => React.ComponentPropsWithoutRef<"g">;
  onSegmentClick?: (node: ColorWheelNode) => void;
}) => {
  const radius = size / 2;

  const svgRef = useRef<SVGSVGElement>(null);
  const [viewBox, setViewBox] = useState("0,0,0,0");

  const partition = (d: ColorWheelData) =>
    d3partition<ColorWheelData>().size([2 * Math.PI, radius])(
      hierarchy(d).sum((d) => d.value ?? 0)
    );

  const arc = d3arc<ColorWheelNode>()
    .startAngle((d) => d.x0)
    .endAngle((d) => d.x1)
    .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.0))
    .padRadius(radius / 2)
    .innerRadius((d) => d.y0)
    .outerRadius((d) => d.y1 - 1);

  const getAutoBox = () => {
    if (!svgRef.current) {
      return "";
    }

    const { x, y, width, height } = svgRef.current.getBBox();

    return [x, y, width, height].toString();
  };

  useEffect(() => {
    setViewBox(getAutoBox());
  }, []);

  const root = partition(data);
  const segmentRotationDegrees = 15;

  const tonicRotation = () => {
    let rotationFactor = 1;
    const notes = root.children ?? [];
    for (let i = 0; i < notes.length; i++) {
      if (notes[i].data.name === tonic) {
        rotationFactor = i;
        break;
      }
    }

    const rotate =
      rotationFactor === 0
        ? segmentRotationDegrees
        : (rotationFactor + rotationFactor - 1) * segmentRotationDegrees +
          segmentRotationDegrees * 2;

    return `rotate(-${rotate})`;
  };

  return (
    <svg
      transform={tonicRotation()}
      width={size}
      height={size}
      viewBox={viewBox}
      ref={svgRef}
    >
      <g>
        {root
          .descendants()
          .filter((node) => node.depth)
          .map((node, i) => (
            <path
              onClick={() => onSegmentClick(node)}
              key={`${node.data.name}-${i}`}
              stroke="grey"
              d={arc(node) as string}
              fill={NoteRGB(node.data.name)}
              {...nodeProps(node.data)}
            ></path>
          ))}
      </g>
      <g
        pointerEvents="none"
        textAnchor="middle"
        fontSize={20}
        fontFamily="sans-serif"
      >
        {root
          .descendants()
          .filter(
            (node) =>
              node.depth && ((node.y0 + node.y1) / 2) * (node.x1 - node.x0) > 10
          )
          .map((node, i) => (
            <ColorWheelNote
              key={i}
              data={node.data}
              node={node}
              nodeProps={nodeProps}
            />
          ))}
      </g>
    </svg>
  );
};
