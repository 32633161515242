export type ColorMusicColor =
  | "red" // C
  | "red-orange" // G
  | "orange" // D
  | "orange-yellow" // A
  | "yellow" // E
  | "yellow-green" // B
  | "green" // G flat
  | "green-blue" // D flat
  | "blue" // A flat
  | "blue-purple" // E flat
  | "purple" // B flat
  | "purple-red"; // F

export const NoteTextColor = (note: string) => {
  const color = NoteColor(note)

  return color === "yellow" || color === "orange-yellow" || color === "yellow-green"
    ? "#000"
    : "#fff";
}

export  const NoteColor = (note: string) => {
  if (note.length >= 2) {
    const prefix = note.slice(0,2)
    const color = NoteLetterColors[prefix]
    if (color !== undefined) {
      return color
    }
  }

  return NoteLetterColors[note[0]]
}

export const NoteRGB = (note: string) => {
  return ColorNameRGBMapping[NoteColor(note)]
}

export const NoteLetterColors: Record<string, ColorMusicColor> = {
  "C": "red",
  "G": "red-orange",
  "D": "orange",
  "A": "orange-yellow",
  "E":"yellow",
  "B":"yellow-green",

  "F#":"green",
  "F♯":"green",
  "Gb":"green",
  "G♭":"green",

  "C#":"green-blue",
  "C♯":"green-blue",
  "Db":"green-blue",
  "D♭":"green-blue",

  "G#":"blue",
  "G♯":"blue",
  "Ab":"blue",
  "A♭":"blue",

  "D#":"blue-purple",
  "D♯":"blue-purple",
  "Eb":"blue-purple",
  "E♭":"blue-purple",

  "A#":"purple",
  "A♯":"purple",
  "Bb":"purple",
  "B♭":"purple",

  "F": "purple-red"
}

export const ColorRGB = (name: ColorMusicColor) => {
  return ColorNameRGBMapping[name]
}
export const ColorNameRGBMapping: Record<ColorMusicColor, string> = {
  "red": "rgb(218,30,72)",
  "red-orange": "rgb(240,78,35)",
  "orange": "rgb(245,130,32)",
  "orange-yellow": "rgb(253,185,19)",
  "yellow": "rgb(243,220,11)",
  "yellow-green": "rgb(166,206,57)",
  "green": "rgb(57,181,74)",
  "green-blue": "rgb(0,169,149)",
  "blue": "rgb(0,152,221)",
  "blue-purple": "rgb(55,99,175)",
  "purple": "rgb(130,63,152)",
  "purple-red": "rgb(171,33,142)",
};
