import { Icon } from "@chakra-ui/icons";
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiHome, FiSearch } from "react-icons/fi";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { MdQueueMusic, MdOutlineColorLens } from "react-icons/md";
import { SiCircle, SiMidi } from "react-icons/si";
import { Link } from "react-router-dom";

import { Logo } from "./Logo";
import { NavButton } from "./NavButton";

export const Sidebar = () => (
  <Flex as="section" minH="100vh" bg="bg-canvas">
    <Flex
      flex="1"
      bg="bg-surface"
      overflowY="auto"
      boxShadow={useColorModeValue("md", "sm-dark")}
      maxW={{ base: "full", sm: "xs" }}
      py={{ base: "6", sm: "8" }}
      px={{ base: "4", sm: "6" }}
    >
      <Stack justify="space-between" spacing="1">
        <Stack spacing={{ base: "5", sm: "6" }} shouldWrapChildren>
          <Logo />
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={FiSearch} color="muted" boxSize="5" />
            </InputLeftElement>
            <Input placeholder="Search" />
          </InputGroup>
          <Stack spacing="1">
            <Link to="/">
              <NavButton label="Home" icon={FiHome} />
            </Link>
            <Link to="/harmony">
              <NavButton label="Harmony" icon={SiCircle} />
            </Link>
            <Link to="/melody">
              <NavButton label="Melody" icon={MdQueueMusic} />
            </Link>
            <Link to="/midi">
              <NavButton label="X-Ray" icon={SiMidi} />
            </Link>
            <Link to="/chromatic-scale">
              <NavButton label="Chromatic Scale" icon={MdOutlineColorLens} />
            </Link>
          </Stack>
          <Stack spacing={{ base: "5", sm: "6" }}>
            <Stack spacing="1">
              <NavButton label="Shop" icon={HiOutlineShoppingCart} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  </Flex>
);
