import { Box } from "@chakra-ui/react";
import { NoteRGB, NoteTextColor } from "../Colors";
import { NoteShape } from "../Shapes";

interface Props {
  note: string;
  label?: string;
  fontSize?: string | number;
  width?: string | number;
  height?: string | number;
  lineHeight?: string | number;
}

export const ShapedNote = (props: Props) => {
  const {
    note,
    fontSize = "1em",
    width = "2.4em",
    height = "2.4em",
    lineHeight = "2.4em",
  } = props;
  const color = NoteRGB(note);
  const textColor = NoteTextColor(note);
  const shape = NoteShape(note);
  const label = props.label ?? note;

  return (
    <Box
      fontSize={fontSize}
      fontFamily="Arial, sans-serif"
      fontWeight="bold"
      width={width}
      height={height}
      boxSizing="initial"
      background={color}
      color={textColor}
      border="0.1em solid #000"
      textAlign="center"
      borderRadius={shape === "circle" ? "50%" : "0"}
      lineHeight={lineHeight}
    >
      {label}
    </Box>
  );
};
