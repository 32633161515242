import _ from "lodash";
import {
  Avatar,
  Box,
  ButtonGroup,
  Container,
  Drawer,
  DrawerBody,
  Text,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Spacer,
  Stack,
  useBreakpointValue,
  useDisclosure,
  Heading,
  ListItem,
  OrderedList,
  VStack,
  FormControl,
  FormLabel,
  Select,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  Center,
  Tag,
  Wrap,
  CloseButton,
} from "@chakra-ui/react";
import * as Tonal from "@tonaljs/tonal";
import {
  FiEdit,
  FiHelpCircle,
  FiMenu,
  FiSearch,
  FiSettings,
  FiTrash,
} from "react-icons/fi";
import { HiOutlineSun } from "react-icons/hi";
import { RiPlayListFill } from "react-icons/ri";
import { useColorMode, useColorModeValue } from "@chakra-ui/color-mode";
import {
  loadChordProgressions,
  ChordProgressionIndex,
  store,
  STORED_CHORD_PROGRESSION_KEY,
  SelectedSavedChordProgression,
} from "./Harmony";
import { useContext, useEffect, useState } from "react";
import { ShapedChordProgression } from "./MidiXRay";
import { Mode } from "../Mode";
import { ColorWheelData } from "./ColorWheel";
import circleOfFifthsData from "./../data/circle-of-fifths-notes.json";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import { Chord } from "../Note";
import { SavedChordProgression } from "./Harmony";
import { DefaultInstrument } from "../Instrument";

export const Header = () => {
  const { toggleColorMode } = useColorMode();

  const isDesktop = useBreakpointValue({ base: false, lg: true });
  return (
    <Container>
      <Stack
        spacing="4"
        direction={{ base: "column", md: "row" }}
        justify="space-between"
      >
        <Spacer />
        {isDesktop ? (
          <Box p="4">
            <HStack spacing="4">
              <ButtonGroup variant="ghost-on-accent" spacing="1">
                <IconButton
                  icon={<FiSearch fontSize="1.25rem" />}
                  aria-label="Search"
                />
                <IconButton
                  icon={<FiSettings fontSize="1.25rem" />}
                  aria-label="Settings"
                />
                <IconButton
                  icon={<FiHelpCircle fontSize="1.25rem" />}
                  aria-label="Help Center"
                />
                <SavedProgressionsDrawer />
                <IconButton
                  icon={<HiOutlineSun fontSize="1.25rem" />}
                  aria-label="Color Mode"
                  onClick={toggleColorMode}
                />
              </ButtonGroup>
              <Avatar
                boxSize="10"
                name="Mike George"
                src="https://cdn.locals.com/images/avatars/721244/721244_a9i2abbusfmeo18_thumb.jpeg"
              />
            </HStack>
          </Box>
        ) : (
          <IconButton
            variant="ghost-on-accent"
            icon={<FiMenu fontSize="1.25rem" />}
            aria-label="Open Menu"
          />
        )}
      </Stack>
    </Container>
  );
};

interface ChordProgressionFilter {
  key?: string;
  mode?: Mode;
  chordQuery?: string;
}

const sortChordProgressions = (progressions: SavedChordProgression[]) => {
  return progressions.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
};

const SavedProgressionsDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    setSelectedSavedChordProgression,
    reloadSavedChordProgressions,
    setReloadSavedChordProgressions,
  } = useContext(SelectedSavedChordProgression);
  const [chordProgressionIndex, setChordProgressionIndex] =
    useState<ChordProgressionIndex>(() => {
      return loadChordProgressions() ?? {};
    });

  const [filter, setFilter] = useState<ChordProgressionFilter>(
    {} as ChordProgressionFilter
  );
  const [filteredChordProgressions, setFilteredChordProgressions] = useState<
    SavedChordProgression[]
  >(() => {
    return sortChordProgressions(Object.values(chordProgressionIndex));
  });

  const circleOfFifths = circleOfFifthsData as ColorWheelData;

  useEffect(() => {
    if (reloadSavedChordProgressions) {
      setChordProgressionIndex(loadChordProgressions());
      setReloadSavedChordProgressions(false);
    }
  }, [reloadSavedChordProgressions]);

  useEffect(() => {
    console.log("filter changed", { filter });
    setFilteredChordProgressions(
      Object.values(chordProgressionIndex).filter((scp) => {
        if (filter.key != null) {
          return filter.key === scp.keySignature;
        }

        if (filter.mode != null) {
          return filter.mode === scp.mode;
        }

        if (filter.chordQuery != null) {
          const chords = filter.chordQuery.split(" ");
          const foundMatch = scp.progression.chords.find((chord) => {
            return chords.find((c) => {
              return chord.chord.name === new Chord(c).chord.name;
            });
          });
          return foundMatch;
        } else {
          return true;
        }
      })
    );
  }, [filter]);

  const normalizeChordSearch = (query: string) => {
    const chars = query.split("");
    const normalized = chars
      .map((char, idx) => {
        switch (char.toLowerCase()) {
          case "a":
          case "c":
          case "d":
          case "e":
          case "f":
          case "g":
            return char.toUpperCase();
          case "b": {
            if (idx === 0) {
              return char.toUpperCase();
            }

            const previousChar = chars[idx - 1];

            if (previousChar === "") {
              return char.toUpperCase();
            }

            return char.toLowerCase();
          }
          case "#":
            return "#";
          case "*":
          case "˚":
            return "˚";
          case "m":
            return "m";
          case " ":
            return " ";
          default:
            return "";
        }
      })
      .join("");

    return normalized;
  };

  return (
    <>
      <IconButton
        icon={<RiPlayListFill fontSize="1.25rem" />}
        aria-label="Color Mode"
        onClick={onOpen}
      />
      <Drawer onClose={onClose} isOpen={isOpen} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <HStack>
              <Heading size="sm">Saved Chord Progressions</Heading>
              <Spacer />
              <CloseButton size="md" onClick={onClose} />
            </HStack>
          </DrawerHeader>
          <DrawerBody>
            <VStack>
              <Box
                as="form"
                bg="bg-surface"
                borderRadius="lg"
                boxShadow={useColorModeValue("sm", "sm-dark")}
                maxW={{ lg: "3xl" }}
                width="100%"
              >
                <Stack
                  spacing="2"
                  px={{ base: "4", md: "6" }}
                  py={{ base: "5", md: "6" }}
                >
                  <Stack
                    alignContent="space-evenly"
                    spacing="2"
                    direction={{ base: "column", md: "row" }}
                  >
                    <Box>
                      <Text fontSize="lg" fontWeight="medium">
                        Filter
                      </Text>
                    </Box>
                  </Stack>
                  <Divider />
                  <HStack spacing="2">
                    <FormControl flex={1}>
                      <FormLabel htmlFor="search">Chord</FormLabel>
                      <InputGroup>
                        <InputLeftElement pointerEvents="none">
                          <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input
                          size="sm"
                          type="search"
                          onClick={(e) => {
                            e.currentTarget.value = "";
                            setFilter((oldFilter) => {
                              return {
                                ...oldFilter,
                                chordQuery: undefined,
                              };
                            });
                          }}
                          placeholder="Filter by chord"
                          onChange={(e) => {
                            const query = normalizeChordSearch(
                              e.currentTarget.value
                            );
                            e.currentTarget.value = query;
                            setFilter((oldFilter) => {
                              return {
                                ...oldFilter,
                                chordQuery: query === "" ? undefined : query,
                              };
                            });

                            console.log("searching by chord", {
                              raw: e.currentTarget.value,
                              normalized: query,
                            });
                          }}
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl flex={1}>
                      <FormLabel htmlFor="key">Key</FormLabel>
                      <Select
                        size="sm"
                        id="key"
                        placeholder="Filter by key"
                        onChange={(e) => {
                          const key =
                            e.currentTarget.value !== ""
                              ? e.currentTarget.value
                              : undefined;

                          setFilter((oldFilter) => {
                            return {
                              ...oldFilter,
                              key: key,
                            };
                          });
                        }}
                      >
                        {circleOfFifths.children?.map(
                          (value: ColorWheelData) => {
                            return (
                              <option key={value.name} value={value.name}>
                                {value.name}
                              </option>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>
                    <FormControl flex={1}>
                      <FormLabel htmlFor="mode">Mode</FormLabel>
                      <Select
                        size="sm"
                        placeholder="Filter by mode"
                        id="mode"
                        onChange={(e) => {
                          const mode =
                            e.currentTarget.value !== ""
                              ? (e.currentTarget.value as Mode)
                              : undefined;

                          setFilter((oldFilter) => {
                            return {
                              ...oldFilter,
                              mode: mode,
                            };
                          });
                        }}
                      >
                        {Tonal.Mode.names().map((mode: string) => {
                          return (
                            <option key={mode} value={mode}>
                              {_.capitalize(mode)}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </HStack>
                </Stack>
              </Box>

              <OrderedList
                listStylePosition="outside"
                width="90%"
                spacing={4}
                paddingTop="5"
              >
                {filteredChordProgressions.length === 0 ? (
                  <Center>
                    <Text>No Matches</Text>
                  </Center>
                ) : (
                  sortChordProgressions(filteredChordProgressions).map(
                    (scp) => {
                      return (
                        <ListItem key={scp.name}>
                          <Box
                            bg={useColorModeValue("white", "gray.900")}
                            p={{ base: "3", md: "4" }}
                            rounded={{ sm: "lg" }}
                            shadow={{ md: "base" }}
                          >
                            <HStack mb="2">
                              <ShapedChordProgression
                                size="sm"
                                progression={scp.progression}
                              />
                              <Spacer />
                              <IconButton
                                title="Edit chord progressions"
                                icon={<FiEdit />}
                                variant="ghost"
                                aria-label="Edit"
                                fontSize="1.25em"
                                onClick={() => {
                                  console.log("loading chord progressions", {
                                    scp,
                                  });
                                  setSelectedSavedChordProgression(scp);
                                  onClose();
                                }}
                              />
                              <IconButton
                                title="Delete chord progression"
                                onClick={() => {
                                  const progressions = loadChordProgressions();
                                  delete progressions[scp.name];
                                  store(
                                    STORED_CHORD_PROGRESSION_KEY,
                                    progressions
                                  );
                                  setChordProgressionIndex(progressions);
                                  console.log("deleted chord progressions", {
                                    scp,
                                  });
                                }}
                                aria-label="Delete"
                                icon={<FiTrash />}
                                variant="ghost"
                                fontSize="1.25em"
                              />
                            </HStack>
                            <HStack>
                              <Wrap>
                                <Tag>{scp.keySignature}</Tag>
                                <Tag>{scp.mode}</Tag>
                                <Tag>
                                  {scp.instrumentConfig != null
                                    ? scp.instrumentConfig.kind
                                    : DefaultInstrument}
                                </Tag>
                                {scp.tags?.map((tag) => (
                                  <Tag key={tag}>{tag}</Tag>
                                ))}
                                <IconButton
                                  aria-label="Add tag"
                                  size="sm"
                                  icon={<AddIcon />}
                                />
                              </Wrap>
                              <Spacer />
                              <Text
                                fontStyle="italic"
                                fontWeight="thin"
                                fontSize="xs"
                              >
                                {new Date(scp.createdAt).toLocaleDateString(
                                  "en-us",
                                  {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  }
                                )}
                              </Text>
                            </HStack>
                          </Box>
                        </ListItem>
                      );
                    }
                  )
                )}
              </OrderedList>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
