import { Sampler } from "tone";

export const DefaultInstrument: InstrumentKind = "piano";

export interface InstrumentConfig {
  kind: InstrumentKind;
  defaultRelease: number;
  defaultOctave: number;
  release?: number;
  octave?: number;
}

export class Instrument {
  kind: InstrumentKind;
  sampler?: Sampler;

  constructor(kind: InstrumentKind, sampler?: Sampler) {
    this.kind = kind;
    this.sampler = sampler;
  }

  load(): void {
    if (this.sampler !== undefined) {
      return;
    }

    console.log("loading", { instrument: this.kind });
    const config = InstrumentConfigs[this.kind];
    this.sampler = new Sampler({
      urls: InstrumentFiles[this.kind],
      release: config.defaultRelease,
      baseUrl: `/samples/instrument/${this.kind}/`,
    });
  }
}

export class InstrumentLibrary {
  private instruments: Record<InstrumentKind, Instrument | undefined>;

  constructor(...instrumentsToLoad: InstrumentKind[]) {
    this.instruments = {
      "bass-electric": undefined,
      "french-horn": undefined,
      "guitar-acoustic": undefined,
      "guitar-electric": undefined,
      "guitar-nylon": undefined,
      bassoon: undefined,
      cello: undefined,
      clarinet: undefined,
      contrabass: undefined,
      flute: undefined,
      harmonium: undefined,
      harp: undefined,
      organ: undefined,
      piano: undefined,
      saxophone: undefined,
      trombone: undefined,
      trumpet: undefined,
      tuba: undefined,
      violin: undefined,
      xylophone: undefined,
    };

    instrumentsToLoad.forEach((k) => this.load(k));
  }

  samplerFor(kind: InstrumentKind) {
    return this.load(kind).sampler as Sampler;
  }

  load(kind: InstrumentKind) {
    let instrument = this.instruments[kind];
    if (instrument === undefined) {
      instrument = new Instrument(kind);
      this.instruments[kind] = instrument;
    }

    instrument.load();

    return instrument;
  }
}

export interface InstrumentLabel {
  displayName: string;
  kind: InstrumentKind;
}

export const InstrumentLabels: InstrumentLabel[] = [
  { kind: "piano", displayName: "Piano" },
  { kind: "guitar-acoustic", displayName: "Acoustic Guitar" },
  { kind: "violin", displayName: "Violin" },
  { kind: "bass-electric", displayName: "Electric Bass" },
  { kind: "bassoon", displayName: "Bassoon" },
  { kind: "cello", displayName: "Cello" },
  { kind: "clarinet", displayName: "Clarinet" },
  { kind: "contrabass", displayName: "Contrabass" },
  { kind: "flute", displayName: "Flute" },
  { kind: "french-horn", displayName: "French Horn" },
  { kind: "guitar-electric", displayName: "Electric Guitar" },
  { kind: "guitar-nylon", displayName: "Nylon Guitar" },
  { kind: "harmonium", displayName: "Harmonium" },
  { kind: "harp", displayName: "Harp" },
  { kind: "organ", displayName: "Organ" },
  { kind: "saxophone", displayName: "Saxophone" },
  { kind: "trombone", displayName: "Trombone" },
  { kind: "trumpet", displayName: "Trumpet" },
  { kind: "tuba", displayName: "Tuba" },
  { kind: "xylophone", displayName: "Xylophone" },
];

export const InstrumentConfigs: Record<InstrumentKind, InstrumentConfig> = {
  violin: {
    defaultRelease: 1,
    defaultOctave: 4,
    kind: "violin",
  },
  "guitar-acoustic": {
    defaultRelease: 50,
    defaultOctave: 2,
    kind: "guitar-acoustic",
  },
  "guitar-electric": {
    defaultRelease: 100,
    defaultOctave: 3,
    kind: "guitar-electric",
  },
  piano: {
    defaultRelease: 1,
    defaultOctave: 3,
    kind: "piano",
  },
  cello: {
    defaultRelease: 2,
    defaultOctave: 4,
    kind: "cello",
  },
  clarinet: {
    defaultRelease: 1,
    defaultOctave: 3,
    kind: "clarinet",
  },
  trumpet: {
    defaultRelease: 2,
    defaultOctave: 2,
    kind: "trumpet",
  },
  "french-horn": {
    defaultRelease: 1,
    defaultOctave: 2,
    kind: "french-horn",
  },
  organ: {
    defaultRelease: 1,
    defaultOctave: 3,
    kind: "organ",
  },
  trombone: {
    defaultRelease: 1,
    defaultOctave: 2,
    kind: "trombone",
  },
  contrabass: {
    defaultRelease: 1,
    defaultOctave: 2,
    kind: "contrabass",
  },
  flute: {
    defaultRelease: 1,
    defaultOctave: 3,
    kind: "flute",
  },
  harmonium: {
    defaultRelease: 1,
    defaultOctave: 3,
    kind: "harmonium",
  },
  harp: {
    defaultRelease: 50,
    defaultOctave: 4,
    kind: "harp",
  },
  bassoon: {
    defaultRelease: 10,
    defaultOctave: 2,
    kind: "bassoon",
  },
  "guitar-nylon": {
    defaultRelease: 50,
    defaultOctave: 4,
    kind: "guitar-nylon",
  },
  tuba: {
    defaultRelease: 1,
    defaultOctave: 1,
    kind: "tuba",
  },
  "bass-electric": {
    defaultRelease: 10,
    defaultOctave: 2,
    kind: "bass-electric",
  },
  saxophone: {
    defaultRelease: 2,
    defaultOctave: 2,
    kind: "saxophone",
  },
  xylophone: {
    defaultRelease: 1,
    defaultOctave: 3,
    kind: "xylophone",
  },
};

export type InstrumentKind =
  | "piano"
  | "bass-electric"
  | "bassoon"
  | "cello"
  | "clarinet"
  | "contrabass"
  | "flute"
  | "french-horn"
  | "guitar-acoustic"
  | "guitar-electric"
  | "guitar-nylon"
  | "harmonium"
  | "harp"
  | "organ"
  | "saxophone"
  | "trombone"
  | "trumpet"
  | "tuba"
  | "violin"
  | "xylophone";

export const InstrumentFiles: Record<InstrumentKind, Record<string, string>> = {
  piano: {
    A2: "A2.mp3",
    A3: "A3.mp3",
    A4: "A4.mp3",
    A5: "A5.mp3",
    "A#2": "As2.mp3",
    "A#3": "As3.mp3",
    "A#4": "As4.mp3",
    "A#5": "As5.mp3",
    B2: "B2.mp3",
    B3: "B3.mp3",
    B4: "B4.mp3",
    B5: "B5.mp3",
    C2: "C2.mp3",
    C3: "C3.mp3",
    C4: "C4.mp3",
    C5: "C5.mp3",
    "C#2": "Cs2.mp3",
    "C#3": "Cs3.mp3",
    "C#4": "Cs4.mp3",
    "C#5": "Cs5.mp3",
    D2: "D2.mp3",
    D3: "D3.mp3",
    D4: "D4.mp3",
    D5: "D5.mp3",
    "D#2": "Ds2.mp3",
    "D#3": "Ds3.mp3",
    "D#4": "Ds4.mp3",
    "D#5": "Ds5.mp3",
    E2: "E2.mp3",
    E3: "E3.mp3",
    E4: "E4.mp3",
    E5: "E5.mp3",
    F2: "F2.mp3",
    F3: "F3.mp3",
    F4: "F4.mp3",
    F5: "F5.mp3",
    "F#2": "Fs2.mp3",
    "F#3": "Fs3.mp3",
    "F#4": "Fs4.mp3",
    "F#5": "Fs5.mp3",
    G2: "G2.mp3",
    G3: "G3.mp3",
    G4: "G4.mp3",
    G5: "G5.mp3",
    "G#2": "Gs2.mp3",
    "G#3": "Gs3.mp3",
    "G#4": "Gs4.mp3",
    "G#5": "Gs5.mp3",
  },
  "bass-electric": {
    "A#2": "As2.mp3",
    "A#3": "As3.mp3",
    "A#4": "As4.mp3",
    "A#5": "As5.mp3",
    "C#2": "Cs2.mp3",
    "C#3": "Cs3.mp3",
    "C#4": "Cs4.mp3",
    "C#5": "Cs5.mp3",
    E2: "E2.mp3",
    E3: "E3.mp3",
    E4: "E4.mp3",
    E5: "E5.mp3",
    G2: "G2.mp3",
    G3: "G3.mp3",
    G4: "G4.mp3",
    G5: "G5.mp3",
  },
  bassoon: {
    A3: "A3.mp3",
    C2: "C2.mp3",
    C3: "C3.mp3",
    C4: "C4.mp3",
    E3: "E3.mp3",
    G2: "G2.mp3",
    G3: "G3.mp3",
    A2: "A2.mp3",
  },
  cello: {
    E3: "E3.mp3",
    E4: "E4.mp3",
    F2: "F2.mp3",
    F3: "F3.mp3",
    F4: "F4.mp3",
    "F#3": "Fs3.mp3",
    "F#4": "Fs4.mp3",
    G2: "G2.mp3",
    G3: "G3.mp3",
    G4: "G4.mp3",
    "G#2": "Gs2.mp3",
    "G#3": "Gs3.mp3",
    "G#4": "Gs4.mp3",
    A2: "A2.mp3",
    A3: "A3.mp3",
    A4: "A4.mp3",
    "A#2": "As2.mp3",
    "A#3": "As3.mp3",
    "A#4": "As4.mp3",
    B2: "B2.mp3",
    B3: "B3.mp3",
    B4: "B4.mp3",
    C2: "C2.mp3",
    C3: "C3.mp3",
    C4: "C4.mp3",
    C5: "C5.mp3",
    "C#3": "Cs3.mp3",
    "C#4": "Cs4.mp3",
    D2: "D2.mp3",
    D3: "D3.mp3",
    D4: "D4.mp3",
    "D#2": "Ds2.mp3",
    "D#3": "Ds3.mp3",
    "D#4": "Ds4.mp3",
    E2: "E2.mp3",
  },
  clarinet: {
    D3: "D3.mp3",
    D4: "D4.mp3",
    D5: "D5.mp3",
    F2: "F2.mp3",
    F3: "F3.mp3",
    F4: "F4.mp3",
    "F#5": "Fs5.mp3",
    "A#2": "As2.mp3",
    "A#3": "As3.mp3",
    "A#4": "As4.mp3",
    D2: "D2.mp3",
  },
  contrabass: {
    "C#2": "Cs2.mp3",
    E2: "E2.mp3",
    "G#2": "Gs2.mp3",
    B2: "B2.mp3",
  },
  flute: {
    A5: "A5.mp3",
    C3: "C3.mp3",
    C4: "C4.mp3",
    C5: "C5.mp3",
    E3: "E3.mp3",
    E4: "E4.mp3",
    E5: "E5.mp3",
    A3: "A3.mp3",
    A4: "A4.mp3",
  },
  "french-horn": {
    D2: "D2.mp3",
    D4: "D4.mp3",
    F2: "F2.mp3",
    F4: "F4.mp3",
    A2: "A2.mp3",
    C3: "C3.mp3",
  },
  "guitar-acoustic": {
    F3: "F3.mp3",
    "F#2": "Fs2.mp3",
    "F#3": "Fs3.mp3",
    G2: "G2.mp3",
    G3: "G3.mp3",
    "G#2": "Gs2.mp3",
    "G#3": "Gs3.mp3",
    A2: "A2.mp3",
    A3: "A3.mp3",
    "A#2": "As2.mp3",
    "A#3": "As3.mp3",
    B2: "B2.mp3",
    B3: "B3.mp3",
    C2: "C2.mp3",
    C3: "C3.mp3",
    C4: "C4.mp3",
    "C#2": "Cs2.mp3",
    "C#3": "Cs3.mp3",
    "C#4": "Cs4.mp3",
    D2: "D2.mp3",
    D3: "D3.mp3",
    D4: "D4.mp3",
    "D#2": "Ds2.mp3",
    "D#3": "Ds3.mp3",
    E2: "E2.mp3",
    E3: "E3.mp3",
    F2: "F2.mp3",
  },
  "guitar-electric": {
    "D#3": "Ds3.mp3",
    "D#4": "Ds4.mp3",
    "D#5": "Ds5.mp3",
    E2: "E2.mp3",
    "F#2": "Fs2.mp3",
    "F#3": "Fs3.mp3",
    "F#4": "Fs4.mp3",
    "F#5": "Fs5.mp3",
    A2: "A2.mp3",
    A3: "A3.mp3",
    A4: "A4.mp3",
    A5: "A5.mp3",
    C3: "C3.mp3",
    C4: "C4.mp3",
    C5: "C5.mp3",
    "C#2": "Cs2.mp3",
  },
  "guitar-nylon": {
    "F#2": "Fs2.mp3",
    "F#3": "Fs3.mp3",
    "F#4": "Fs4.mp3",
    "F#5": "Fs5.mp3",
    G3: "G3.mp3",
    G5: "G3.mp3",
    "G#2": "Gs2.mp3",
    "G#4": "Gs4.mp3",
    "G#5": "Gs5.mp3",
    A2: "A2.mp3",
    A3: "A3.mp3",
    A4: "A4.mp3",
    A5: "A5.mp3",
    "A#5": "As5.mp3",
    B2: "B2.mp3",
    B3: "B3.mp3",
    B4: "B4.mp3",
    "C#3": "Cs3.mp3",
    "C#4": "Cs4.mp3",
    "C#5": "Cs5.mp3",
    D2: "D2.mp3",
    D3: "D3.mp3",
    D5: "D5.mp3",
    "D#4": "Ds4.mp3",
    E2: "E2.mp3",
    E3: "E3.mp3",
    E4: "E4.mp3",
    E5: "E5.mp3",
  },
  harmonium: {
    C2: "C2.mp3",
    C3: "C3.mp3",
    C4: "C4.mp3",
    C5: "C5.mp3",
    "C#2": "Cs2.mp3",
    "C#3": "Cs3.mp3",
    "C#4": "Cs4.mp3",
    "C#5": "Cs5.mp3",
    D2: "D2.mp3",
    D3: "D3.mp3",
    D4: "D4.mp3",
    D5: "D5.mp3",
    "D#2": "Ds2.mp3",
    "D#3": "Ds3.mp3",
    "D#4": "Ds4.mp3",
    E2: "E2.mp3",
    E3: "E3.mp3",
    E4: "E4.mp3",
    F2: "F2.mp3",
    F3: "F3.mp3",
    F4: "F4.mp3",
    "F#2": "Fs2.mp3",
    "F#3": "Fs3.mp3",
    G2: "G2.mp3",
    G3: "G3.mp3",
    G4: "G4.mp3",
    "G#2": "Gs2.mp3",
    "G#3": "Gs3.mp3",
    "G#4": "Gs4.mp3",
    A2: "A2.mp3",
    A3: "A3.mp3",
    A4: "A4.mp3",
    "A#2": "As2.mp3",
    "A#3": "As3.mp3",
    "A#4": "As4.mp3",
  },
  harp: {
    C5: "C5.mp3",
    D2: "D2.mp3",
    D4: "D4.mp3",
    E3: "E3.mp3",
    E5: "E5.mp3",
    F2: "F2.mp3",
    F4: "F4.mp3",
    G3: "G3.mp3",
    G5: "G5.mp3",
    A2: "A2.mp3",
    A4: "A4.mp3",
    B3: "B3.mp3",
    B5: "B5.mp3",
    C3: "C3.mp3",
  },
  organ: {
    C3: "C3.mp3",
    C4: "C4.mp3",
    C5: "C5.mp3",
    "D#2": "Ds2.mp3",
    "D#3": "Ds3.mp3",
    "D#4": "Ds4.mp3",
    "D#5": "Ds5.mp3",
    "F#2": "Fs2.mp3",
    "F#3": "Fs3.mp3",
    "F#4": "Fs4.mp3",
    "F#5": "Fs5.mp3",
    A2: "A2.mp3",
    A3: "A3.mp3",
    A4: "A4.mp3",
    A5: "A5.mp3",
    C2: "C2.mp3",
  },
  saxophone: {
    "D#4": "Ds4.mp3",
    E2: "E2.mp3",
    E3: "E3.mp3",
    E4: "E4.mp3",
    F2: "F2.mp3",
    F3: "F3.mp3",
    F4: "F4.mp3",
    "F#2": "Fs2.mp3",
    "F#3": "Fs3.mp3",
    "F#4": "Fs4.mp3",
    G2: "G2.mp3",
    G3: "G3.mp3",
    G4: "G4.mp3",
    "G#2": "Gs2.mp3",
    "G#3": "Gs3.mp3",
    "G#4": "Gs4.mp3",
    A3: "A3.mp3",
    A4: "A4.mp3",
    "A#2": "As2.mp3",
    "A#3": "As3.mp3",
    B2: "B2.mp3",
    B3: "B3.mp3",
    C3: "C3.mp3",
    C4: "C4.mp3",
    "C#2": "Cs2.mp3",
    "C#3": "Cs3.mp3",
    "C#4": "Cs4.mp3",
    D2: "D2.mp3",
    D3: "D3.mp3",
    D4: "D4.mp3",
    "D#2": "Ds2.mp3",
    "D#3": "Ds3.mp3",
  },
  trombone: {
    "A#2": "As2.mp3",
    C2: "C2.mp3",
    C3: "C3.mp3",
    "C#3": "Cs3.mp3",
    D2: "D2.mp3",
    D3: "D3.mp3",
    "D#2": "Ds2.mp3",
    "D#3": "Ds3.mp3",
    F2: "F2.mp3",
    F3: "F3.mp3",
    "G#2": "Gs2.mp3",
  },
  trumpet: {
    C5: "C5.mp3",
    D4: "D4.mp3",
    "D#3": "Ds3.mp3",
    F2: "F2.mp3",
    F3: "F3.mp3",
    F4: "F4.mp3",
    G3: "G3.mp3",
    A2: "A2.mp3",
    A4: "A4.mp3",
    "A#3": "As3.mp3",
    C3: "C3.mp3",
  },
  tuba: {
    "A#2": "As2.mp3",
    D2: "D2.mp3",
    D3: "D3.mp3",
    F2: "F2.mp3",
  },
  violin: {
    A3: "A3.mp3",
    A4: "A4.mp3",
    A5: "A5.mp3",
    C4: "C4.mp3",
    C5: "C5.mp3",
    E4: "E4.mp3",
    E5: "E5.mp3",
    G4: "G4.mp3",
    G5: "G5.mp3",
  },
  xylophone: {
    G3: "G3.mp3",
    G4: "G4.mp3",
    G5: "G5.mp3",
    C4: "C4.mp3",
    C5: "C5.mp3",
  },
};
