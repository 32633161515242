export type ColorMusicShape = "square" | "circle";

export const NoteShape = (note: string): ColorMusicShape => {
  const normalized = note.replace(/m|˚/, "");
  switch (normalized.slice(0, 2)) {
    case "A#":
    case "A♯":
    case "Ab":
    case "A♭":
    case "Bb":
    case "B♭":
    case "F#":
    case "F♯":
    case "G#":
    case "G♯":
    case "Gb":
    case "G♭":
      return "square";
    case "D#":
    case "D♯":
    case "Db":
    case "D♭":
    case "Eb":
    case "E♭":
    case "C#":
    case "C♯":
      return "circle";
    case "C":
    case "D":
    case "E":
      return "square";
    case "G":
    case "A":
    case "B":
    case "F":
      return "circle";
  }

  // N.B. We should never reach here but will just default to square
  console.warn("couldn't find corresponding shape for", { note });
  return "square";
};
