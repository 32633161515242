  import {SemiTone} from "./Note"
  // Intervals of extended meantone temperament
  // Name                 Interval          Pitch (from C)   Roman No.
  export const unison: SemiTone = 0; // C             I
  export const diminishedSecond = unison; // D♭♭
  export const chromaticSemiTone: SemiTone = 1; // C♯
  export const minorSecond = chromaticSemiTone; // D♭
  export const halfTone = minorSecond;
  export const wholeTone: SemiTone = 2; // D             II
  export const diminishedThird = wholeTone; // E♭♭
  export const augmentedSecond: SemiTone = 3; // D♯
  export const minorThird = augmentedSecond; // E♭
  export const majorThird: SemiTone = 4; // E             III
  export const diminishedFourth = majorThird; // F♭
  export const augmentedThird: SemiTone = 5; // E♯
  export const perfectFourth = augmentedThird; // F             IV
  export const augmentedFourth: SemiTone = 6; // F♯
  export const diminishedFifth = augmentedFourth; // G♭
  export const perfectFifth: SemiTone = 7; // G             V
  export const diminishedSixth = perfectFifth; // A♭♭
  export const augmentedFifth: SemiTone = 8; // G♯
  export const minorSixth = augmentedFifth; // A♭
  export const majorSixth: SemiTone = 9; // A             VI
  export const diminishedSeventh = majorSixth; // B♭♭
  export const augmentedSixth: SemiTone = 10; // A♯
  export const minorSeventh = augmentedSixth; // B♭
  export const majorSeventh: SemiTone = 11; // B             VII
  export const diminishedOctave = majorSeventh; // C♭
  export const augmentedSeventh: SemiTone = 12; // B♯
  export const octave = augmentedSeventh; // C             VIII