import _ from "lodash";
import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Stack,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { Mode, ScaleDegree, ScaleDegreesByMode } from "../Mode";
import { ColorWheelData } from "./ColorWheel";
import circleOfFifthsData from "./../data/circle-of-fifths-notes.json";
import { useEffect, useState } from "react";
import { ShapedNote } from "./ShapedNote";
import {
  DefaultInstrument,
  InstrumentConfig,
  InstrumentConfigs,
  InstrumentKind,
  InstrumentLabel,
  InstrumentLabels,
} from "../Instrument";
import { Octave } from "../Note";
import * as Tonal from "@tonaljs/tonal";

interface Props {
  onKeySelect?: (key: string) => void;
  onModeSelect?: (mode: Mode) => void;
  onBPMSelect?: (bpm: number) => void;
  onInstrumentConfigUpdate?: (config: InstrumentConfig) => void;
  keySignature?: string;
  mode?: Mode;
  instrumentConfig?: InstrumentConfig;
}

export const DefaultBPM = 120;

export const HarmonyControls: React.VFC<Props> = (props: Props) => {
  const {
    keySignature,
    mode,
    instrumentConfig,
    onKeySelect = () => {
      // do nothing
    },
    onModeSelect = () => {
      // do nothing
    },
    onBPMSelect = () => {
      // do nothing
    },
    onInstrumentConfigUpdate = () => {
      // do nothing
    },
    // ...rest
  } = props;

  const circleOfFifths = circleOfFifthsData as ColorWheelData;
  const [scaleDegreeLabelKind, setScaleDegreeLabelKind] =
    useState<ScaleDegreeLabelKind>("key");
  useState<InstrumentKind>(DefaultInstrument);
  const [currentInstrumentConfig, setCurrentInstrumentConfig] =
    useState<InstrumentConfig>(
      instrumentConfig ?? InstrumentConfigs[DefaultInstrument]
    );
  const [currentKey, setCurrentKey] = useState<string>(keySignature ?? "C");
  const [currentMode, setCurrentMode] = useState<Mode>(mode ?? "ionian");
  const [bpm, setBPM] = useState<number>(DefaultBPM);
  const [showBPMSliderTooltip, setShowBPMSliderTooltip] =
    useState<boolean>(false);

  useEffect(() => {
    // If the key, mode or instrument config was provided by the parent
    // component, set state to those values even if there are already values
    // set
    if (keySignature != null) {
      console.log("key signature not null so setting it", { keySignature });
      setCurrentKey(keySignature);
    }

    if (instrumentConfig != null) {
      console.log("instrument config not null so setting it", {
        instrumentConfig,
      });
      setCurrentInstrumentConfig(instrumentConfig);
    }

    if (mode != null) {
      setCurrentMode(mode);
    }
  }, [keySignature, mode, instrumentConfig]);

  console.log({ currentKey, keySignature, currentMode, mode });

  const scaleDegrees = () => {
    const notes = ScaleDegreesByMode[currentMode];
    const startKeyIndex = notes.map((n) => n.key).indexOf(currentKey);
    if (startKeyIndex == -1) {
      // TODO for now just return it relative to C if the current key doesn't appear in that mode (e.g. D in Locrian)
      return notes;
    }
    const notesStartingAtCurrentKey: ScaleDegree[] = [];
    for (let i = startKeyIndex; i - startKeyIndex < notes.length; i++) {
      notesStartingAtCurrentKey.push(notes[i % notes.length]);
    }

    console.log({ currentKey, startKeyIndex, notesStartingAtCurrentKey });
    return notesStartingAtCurrentKey;
  };

  const cycleToNextScaleDegreeLabelKind = () => {
    switch (scaleDegreeLabelKind) {
      case "number": {
        setScaleDegreeLabelKind("key");
        return;
      }
      case "key": {
        setScaleDegreeLabelKind("numeral");
        return;
      }
      case "numeral": {
        setScaleDegreeLabelKind("number");
        return;
      }
    }
  };

  return (
    <Box
      as="form"
      bg="bg-surface"
      borderRadius="lg"
      boxShadow={useColorModeValue("sm", "sm-dark")}
      maxW={{ lg: "3xl" }}
    >
      <Stack
        spacing="5"
        px={{ base: "4", md: "6" }}
        py={{ base: "5", md: "6" }}
      >
        <Stack
          spacing="2"
          alignContent="space-evenly"
          direction={{ base: "column", md: "row" }}
        >
          <FormControl flex={2}>
            <FormLabel htmlFor="instrument">Instrument</FormLabel>
            <Select
              id="instrument"
              value={currentInstrumentConfig.kind ?? DefaultInstrument}
              onChange={(e) => {
                const instrument = e.currentTarget.value as InstrumentKind;
                const config = InstrumentConfigs[instrument];
                config.octave = config.octave ?? config.defaultOctave;
                config.release = config.release ?? config.defaultRelease;
                InstrumentConfigs[instrument] = config;
                setCurrentInstrumentConfig(config);
                console.log("updating instrument config", {
                  config,
                  instrument,
                });
                onInstrumentConfigUpdate(config);
              }}
            >
              {InstrumentLabels.map((label: InstrumentLabel) => {
                return (
                  <option
                    key={`instrument-${label.displayName}`}
                    value={label.kind}
                  >
                    {label.displayName}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl flex={1}>
            <FormLabel htmlFor="octave">Octave</FormLabel>
            <NumberInput
              allowMouseWheel
              key={`octave-for-${currentInstrumentConfig.kind}`}
              maxW={70}
              defaultValue={
                currentInstrumentConfig.octave ??
                currentInstrumentConfig.defaultOctave
              }
              min={Octave.min}
              max={Octave.max}
              onChange={(value) => {
                const octave = parseInt(value);
                setCurrentInstrumentConfig((oldConfig) => {
                  const newConfig = { ...oldConfig, octave: octave };
                  console.log("updating instrument config", {
                    newConfig,
                    octave,
                  });
                  InstrumentConfigs[newConfig.kind] = newConfig;
                  onInstrumentConfigUpdate(newConfig);
                  return newConfig;
                });
              }}
            >
              <NumberInputField
                value={
                  currentInstrumentConfig.octave ??
                  currentInstrumentConfig.defaultOctave
                }
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <FormControl flex={1}>
            <FormLabel htmlFor="release">Release</FormLabel>
            <NumberInput
              allowMouseWheel
              key={`release-for-${currentInstrumentConfig.kind}`}
              maxW={70}
              defaultValue={
                currentInstrumentConfig.release ??
                currentInstrumentConfig.defaultRelease
              }
              min={0}
              max={50}
              onChange={(value) => {
                const release = parseInt(value);
                setCurrentInstrumentConfig((oldConfig) => {
                  const newConfig = { release: release, ...oldConfig };
                  console.log("updating instrument config", {
                    newConfig,
                    release,
                  });
                  InstrumentConfigs[newConfig.kind] = newConfig;
                  onInstrumentConfigUpdate(newConfig);
                  return newConfig;
                });
              }}
            >
              <NumberInputField
                value={
                  currentInstrumentConfig.release ??
                  currentInstrumentConfig.defaultRelease
                }
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        </Stack>
        <Stack spacing="2" direction={{ base: "column", md: "row" }}>
          <FormControl>
            <FormLabel htmlFor="key">Key</FormLabel>
            <Select
              id="key"
              value={currentKey}
              onChange={(e) => {
                const key = e.currentTarget.value;
                setCurrentKey(key);
                onKeySelect(key);
              }}
            >
              {circleOfFifths.children?.map((value: ColorWheelData) => {
                return (
                  <option key={value.name} value={value.name}>
                    {value.name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="mode">Mode</FormLabel>
            <Select
              id="mode"
              value={currentMode}
              onChange={(e) => {
                const mode = e.currentTarget.value as Mode;
                setCurrentMode(mode);
                onModeSelect(mode);
              }}
            >
              {Tonal.Mode.names().map((mode: string) => {
                return (
                  <option key={mode} value={mode}>
                    {_.capitalize(mode)}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
        <Box onClick={() => cycleToNextScaleDegreeLabelKind()}>
          <HStack>
            {scaleDegrees().map((sd: ScaleDegree) => {
              return (
                <ShapedNote
                  key={sd.numeral}
                  note={sd.key}
                  label={labelFromScaleDegree(sd, scaleDegreeLabelKind)}
                />
              );
            })}
          </HStack>
        </Box>
        <Stack spacing="6" direction={{ base: "column", md: "row" }}>
          <FormControl>
            <FormLabel htmlFor="bpm">BPM</FormLabel>
            <Slider
              id="bpm"
              defaultValue={DefaultBPM}
              size="md"
              min={30}
              max={210}
              onChange={(v) => {
                console.log("bpm changing", v);
                setBPM(v);
              }}
              onChangeEnd={(v) => {
                console.log("bpm changed", v);
                setBPM(v);
                onBPMSelect(v);
              }}
              onMouseEnter={() => setShowBPMSliderTooltip(true)}
              onMouseLeave={() => setShowBPMSliderTooltip(false)}
            >
              <SliderMark value={60} mt="1" fontSize="sm" ml="-2.5">
                60
              </SliderMark>
              <SliderMark value={90} mt="1" fontSize="sm" ml="-2.5">
                90
              </SliderMark>
              <SliderMark value={120} mt="1" fontSize="sm" ml="-2.5">
                120
              </SliderMark>
              <SliderMark value={150} mt="1" fontSize="sm" ml="-2.5">
                150
              </SliderMark>
              <SliderMark value={180} mt="1" fontSize="sm" ml="-2.5">
                180
              </SliderMark>
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <Tooltip
                hasArrow
                bg="blue.300"
                color="white"
                placement="top"
                isOpen={showBPMSliderTooltip}
                label={`${bpm}`}
              >
                <SliderThumb />
              </Tooltip>
            </Slider>
          </FormControl>
        </Stack>
      </Stack>
    </Box>
  );
};

type ScaleDegreeLabelKind = "key" | "numeral" | "number";
const labelFromScaleDegree = (
  sd: ScaleDegree,
  labelKind: ScaleDegreeLabelKind
) => {
  switch (labelKind) {
    case "key": {
      return sd.key;
    }
    case "numeral": {
      return sd.numeral;
    }
    case "number": {
      return sd.number;
    }
  }

  return sd.key;
};
