import { Routes, Route } from "react-router-dom";
// import { Home } from "./Home";
import { Harmony } from "./Harmony";
import { ChromaticScale } from "./ChromaticScale";
import { MidiXRay } from "./MidiXRay";
import { Sandbox } from "./Sandbox";

export const Content = () => {
  return (
    <Routes>
      <Route path="/" element={<Harmony />} />
      <Route path="/harmony" element={<Harmony />} />
      <Route path="/midi" element={<MidiXRay />} />
      <Route path="/melody" element={<Sandbox />} />
      <Route path="/chromatic-scale" element={<ChromaticScale />} />
    </Routes>
  );
};
