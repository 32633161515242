import _ from "lodash";
import {
  Box,
  Button,
  Flex,
  FlexProps,
  Heading,
  Input,
  useColorModeValue,
  Text,
  HStack,
  VStack,
  Center,
  Icon,
  Square,
  Container,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Spacer,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  AccordionPanel,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Wrap,
  WrapItem,
  IconButton,
  Spinner,
} from "@chakra-ui/react";

import { BsMusicNoteList, BsMusicNote } from "react-icons/bs";
import { CgMathPercent } from "react-icons/cg";
import { AiOutlineInsertRowLeft } from "react-icons/ai";
import {
  GiMetronome,
  GiViolin,
  GiGuitarBassHead,
  GiGuitar,
  GiFrenchHorn,
  GiTrumpet,
  GiHarp,
  GiSaxophone,
  GiTrombone,
  GiTuba,
  GiClarinet,
  GiBassoon,
  GiFlute,
  GiMusicalKeyboard,
} from "react-icons/gi";
import { IoMdMicrophone } from "react-icons/io";
import { MdPiano } from "react-icons/md";
import { FaGuitar, FaUser } from "react-icons/fa";
import { ImHourGlass } from "react-icons/im";
import { useEffect, useRef, useState } from "react";
import { Midi, Track as MidiTrack, Track } from "@tonejs/midi";
import * as Tone from "tone";
import { Note as MidiNote } from "@tonejs/midi/dist/Note";
import { FiPlayCircle, FiStopCircle, FiUploadCloud } from "react-icons/fi";
import {
  Note,
  OldNote,
  Octave,
  pitchFromString,
  Chord,
  Progression,
} from "../Note";
import { ShapedChord } from "./Harmony";
import { Instrument } from "@tonejs/midi/dist/Instrument";
import { DefaultInstrument, InstrumentLibrary } from "../Instrument";
import { CircleOfFifths } from "./CircleOfFifths";
import { ColorWheelData } from "./ColorWheel";
import { CloseIcon } from "@chakra-ui/icons";
import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import axios from "axios";
import { apiUrl } from "../Api";
import Fuse from "fuse.js";

export interface MidiResult {
  id: number;
  artist: string;
  title: string;
}

export const MidiSearchBox = ({
  onSelectedResult,
}: {
  onSelectedResult: (result: MidiResult) => void;
}) => {
  const [searchIndex, setSearchIndex] = useState<Fuse<MidiResult> | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<MidiResult[]>([]);
  const [query, setQuery] = useState<string | null>(null);
  const [selectedResult, setSelectedResult] = useState<MidiResult | undefined>(
    undefined
  );

  useEffect(() => {
    if (results.length > 0) {
      const f = new Fuse(results, {
        includeScore: true,
        useExtendedSearch: true,
        threshold: 0.75,
        keys: ["artist", "title"],
      });
      setSearchIndex(f);
    }
  }, [results]);

  useEffect(() => {
    if (query != null && query.length > 5 && searchIndex != null) {
      const matches = searchIndex?.search(query);
      console.log("fuse matches", { matches });
      setResults(matches.map((m) => m.item));
    }
    console.log({ query });
    if (query == null || query.length < 2 || query.length > 5 || isLoading) {
      console.log("not making request", { query, isLoading });
      return;
    }
    setIsLoading(true);
    axios
      .get(apiUrl("midi/search"), {
        params: { query },
      })
      .then(
        (response) => {
          setResults(response.data as MidiResult[]);
          console.log("result", { query, response });
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
          console.warn("request error", { error });
        }
      );
  }, [query]);

  useEffect(() => {
    if (selectedResult == null) {
      return;
    }
    onSelectedResult(selectedResult);
  }, [selectedResult]);

  return (
    <Flex pt="48" justify="center" align="center" w="full">
      <FormControl w="350px">
        <FormLabel>MIDI Search</FormLabel>
        <AutoComplete
          suggestWhenEmpty={false}
          onSelectOption={({ item }) => {
            const result = results.find((r) => r.id === parseInt(item.value));
            setSelectedResult(result);
            console.log("selected item", { item, result });
          }}
        >
          <AutoCompleteInput
            placeholder="Search by artist or title"
            variant="filled"
            onChange={(e) => {
              setQuery(e.currentTarget.value);
            }}
          />
          <AutoCompleteList w="350px">
            {results.map((result) => (
              <AutoCompleteItem
                key={`option-${result.id}`}
                value={`${result.id}`}
                label={`${result.artist} - ${result.title}`}
              >
                {`${result.artist} - ${result.title}`}
              </AutoCompleteItem>
            ))}
          </AutoCompleteList>
        </AutoComplete>
      </FormControl>
    </Flex>
  );
};

export const MidiXRay = () => {
  const [file, setFile] = useState<File | null>(null);
  const [midi, setMidi] = useState<Midi | null>(null);
  const [xray, setXRay] = useState<XRay | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    console.log({ file });
    if (file === null) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target !== null && e.target.result !== null) {
        // TODO there is a Midi.fromUrl function that could be used to support someone pasting a url
        // in the file upload drop area
        const m = new Midi(e.target.result as ArrayBuffer);
        if (m.header.name.trim().length === 0) {
          m.header.name = file.name;
        }
        m.tracks = m.tracks.filter((t) => t.notes.length > 0);
        setMidi(m);
        setXRay(new XRay(m));
      }
    };
    reader.readAsArrayBuffer(file);
  }, [file]);

  useEffect(() => {
    console.log({ midi });
    if (midi == null) {
      return;
    }
  }, [midi]);

  useEffect(() => {
    if (xray == null) {
      return;
    }

    // console.log({ chords: xray.chords(), progressions: xray.progressions() });
  });

  return (
    <>
      {midi == null && isLoading && (
        <Center>
          <Text fontSize="xl" p={4}>
            Loading
          </Text>
          <Spinner size="xl" />
        </Center>
      )}
      {midi == null && !isLoading && (
        <VStack>
          <MidiSearchBox
            onSelectedResult={(result) => {
              setIsLoading(true);
              const url = apiUrl(`midi/${result.id}/data`);
              console.log("search box returned selected id", url);
              Midi.fromUrl(url).then(
                (m) => {
                  m.header.name = `${result.artist} - ${result.title}`;
                  m.tracks = m.tracks.filter((t) => t.notes.length > 0);
                  setMidi(m);
                  setXRay(new XRay(m));
                  setIsLoading(false);
                },
                (error) => {
                  setIsLoading(false);
                  console.warn("error loading midi", { error });
                }
              );
            }}
          />
          <Text>or</Text>
          <Center
            borderWidth="1px"
            borderRadius="lg"
            px="6"
            py="4"
            bg={useColorModeValue("white", "gray.800")}
            onDrop={(event) => {
              event.preventDefault();
              console.log("drop occurred", {
                event,
                inputRef: fileInputRef.current,
              });
            }}
            onDragOver={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
          >
            <VStack spacing="3">
              <Input
                hidden={true}
                type="file"
                accept="audio/midi"
                ref={fileInputRef}
                onChange={(e) => {
                  console.log({ e });
                  console.log({ files: e.target.files });
                  const files = e.target?.files;

                  if (files == null || files.length === 0) {
                    return;
                  }
                  setFile(files[0]);
                }}
              />
              <Square size="20" bg="bg-subtle" borderRadius="lg">
                <Icon as={FiUploadCloud} boxSize="10" color="muted" />
              </Square>
              <VStack spacing="1">
                <HStack spacing="1" whiteSpace="nowrap">
                  <Button
                    onClick={() => fileInputRef.current?.click()}
                    variant="link"
                    colorScheme="blue"
                    size="lg"
                  >
                    Click to upload
                  </Button>
                  <Text fontSize="lg" color="muted">
                    or drag and drop a MIDI file
                  </Text>
                </HStack>
              </VStack>
            </VStack>
          </Center>
        </VStack>
      )}

      {xray != null && (
        <HStack alignItems="start">
          <MidiDetails xray={xray} />
          <IconButton
            aria-label="Clear Midi Selection"
            icon={<CloseIcon />}
            onClick={() => {
              setMidi(null);
              setXRay(null);
            }}
          />
        </HStack>
      )}
    </>
  );
};

export const MidiDetails = ({ xray }: { xray: XRay }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [synths, setSynths] = useState<Tone.PolySynth[]>([]);

  const togglePlayBack = () => {
    if (isPlaying) {
      stopPlayBack();
      setIsPlaying(false);
    } else {
      startPlayBack();
      setIsPlaying(true);
    }
  };

  const stopPlayBack = () => {
    synths.forEach((s) => s.disconnect());
  };

  const startPlayBack = () => {
    const now = Tone.now() + 0.5;
    setSynths(
      xray.midi.tracks.map((track) => {
        const synth = new Tone.PolySynth(Tone.Synth, {
          envelope: {
            attack: 0.02,
            decay: 0.1,
            sustain: 0.3,
            release: 1,
          },
        }).toDestination();

        track.notes.forEach((note) => {
          synth.triggerAttackRelease(
            note.name,
            note.duration,
            note.time + now,
            note.velocity
          );
        });

        return synth;
      })
    );
  };

  return (
    <Box>
      <VStack spacing="5">
        <HStack alignItems="center">
          <Heading size="lg" p="2">
            {xray.midi.header.name === "" ? (
              <Text fontStyle="italic" textColor="gray.500">
                Title Missing
              </Text>
            ) : (
              xray.midi.header.name
            )}
          </Heading>
          <Button fontSize="2em" size="lg" onClick={togglePlayBack}>
            {isPlaying ? "Stop" : "Play"}
          </Button>
        </HStack>

        <MidiStats xray={xray} />

        <Heading size="md">Chord Heat Map</Heading>
        <CircleOfFifths
          tonic={xray.midi.header.keySignatures[0]?.key
            .replace("b", "♭")
            .replace("#", "♯")}
          nodeProps={(data: ColorWheelData) => {
            const rank = xray.chordRank(data.symbol);
            const opacity = rank === 0 ? 0.05 : rank;
            return {
              opacity: opacity,
              title: "10%",
            };
          }}
        />

        <ChordCounts xray={xray} />
        <ChordSummary xray={xray} />

        <Heading size="md">Chord Progressions</Heading>
        <Accordion allowMultiple allowToggle>
          {Object.entries(xray.progressions())
            .filter(([, p]) => p.length > 0)
            .map(([channel, progressions]) => {
              return (
                <TrackSummary
                  key={channel}
                  xray={xray}
                  channel={Number(channel)}
                  progressions={progressions}
                />
              );
            })}
        </Accordion>
      </VStack>
    </Box>
  );
};

export const ChordCounts = ({ xray }: { xray: XRay }) => {
  const inOrder = Object.entries(xray.chordCounts).sort(
    ([, a], [, b]) => b - a
  );
  return (
    <VStack>
      <Heading size="md">Chord Counts</Heading>
      <Accordion allowMultiple allowToggle>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <HStack>
                <Text as="em" pr="2" fontSize="sm" color="gray.400">
                  {_.size(inOrder)} unique chords
                </Text>
              </HStack>
            </Box>
            <AccordionIcon fontSize="3xl" />
          </AccordionButton>
          <AccordionPanel>
            <Table>
              <Thead>
                <Tr>
                  <Th>Occurances</Th>
                  <Th>Chord</Th>
                </Tr>
              </Thead>
              <Tbody>
                {inOrder.map(([name, occurances]) => {
                  return (
                    <Tr key={name}>
                      <Td>{occurances}</Td>
                      <Td>
                        <ShapedChord key={name} chord={new Chord(name)} />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </VStack>
  );
};

export const ChordSummary = ({ xray }: { xray: XRay }) => {
  const trackChords: {
    track: MidiTrack;
    chordsByMeasure: { measure: number; chords: MidiChord[] }[];
  }[] = [];

  const chordsByTrack = xray.chords();

  const secondsToMinutes = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);

    return (
      minutes.toString().padStart(1, "0") +
      ":" +
      seconds.toString().padStart(2, "0")
    );
  };

  Object.keys(chordsByTrack)
    .map((n) => parseInt(n))
    .sort((a, b) => a - b)
    .forEach((trackNumber) => {
      const track = xray.track(trackNumber);
      if (track == null) {
        return;
      }
      const chordsForTrackByMeasure = xray.getChordsForTrackByMeasure(track);

      const measures = Object.keys(chordsForTrackByMeasure)
        .map((n) => parseInt(n))
        .sort((a, b) => a - b)
        .map((m) => {
          return { measure: m, chords: chordsForTrackByMeasure[m] };
        });

      const trackChord = {
        track: track,
        chordsByMeasure: measures,
      };
      trackChords.push(trackChord);
    });
  console.log({ trackChords, chordsByTrack });

  return (
    <VStack>
      <Heading size="md">Chords by Track</Heading>
      <Accordion allowMultiple allowToggle>
        {trackChords
          .filter((tc) => tc.chordsByMeasure.length > 10)
          .map((tc) => {
            return (
              <AccordionItem key={tc.track.channel}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <HStack>
                      <TrackDescription track={tc.track} /> <Spacer />
                      <Text as="em" pr="2" fontSize="sm" color="gray.400">
                        {tc.chordsByMeasure.length} measures
                      </Text>
                    </HStack>
                  </Box>
                  <AccordionIcon fontSize="3xl" />
                </AccordionButton>
                <AccordionPanel>
                  <Table key={tc.track.channel}>
                    <Thead>
                      <Tr>
                        <Th>Time</Th>
                        <Th>Measure</Th>
                        <Th>Chords</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {tc.chordsByMeasure.map(({ measure, chords }) => {
                        return (
                          <Tr key={measure}>
                            <Th>
                              {secondsToMinutes(chords[0].midiNotes[0].time)}
                            </Th>
                            <Td>{measure}</Td>
                            <Td>
                              <ShapedChordProgression
                                key={measure}
                                progression={
                                  new Progression(chords.map((mc) => mc.chord))
                                }
                              />
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </AccordionPanel>
              </AccordionItem>
            );
          })}
      </Accordion>
    </VStack>
  );
};

export const TrackSummary = ({
  xray,
  channel,
  progressions,
}: {
  xray: XRay;
  channel: number;
  progressions: Progression[];
}) => {
  const track = xray.track(channel);

  if (track == null) {
    console.warn("couldn't find track", { channel, xray });
    return null;
  }

  const progressionCounts = groupBy(progressions, (p) => {
    return p.toString();
  });

  const keysInDescendingOrder = Object.keys(progressionCounts).sort(
    (a, b) => progressionCounts[b].length - progressionCounts[a].length
  );

  return (
    <AccordionItem key={track.channel}>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          <HStack>
            <TrackDescription track={track} /> <Spacer />
            <Text as="em" pr="2" fontSize="sm" color="gray.400">
              {_.size(progressionCounts)} progressions
            </Text>
          </HStack>
        </Box>
        <AccordionIcon fontSize="3xl" />
      </AccordionButton>
      <AccordionPanel>
        <Table key={track.channel}>
          <Thead>
            <Tr>
              <Th>Occurances</Th>
              <Th>Progression</Th>
            </Tr>
          </Thead>
          <Tbody>
            {keysInDescendingOrder.map((progressionName) => {
              return (
                <Tr key={progressionName}>
                  <Th>{progressionCounts[progressionName].length}</Th>
                  <Td>
                    <ShapedChordProgression
                      progression={progressionCounts[progressionName][0]}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </AccordionPanel>
    </AccordionItem>
  );
  // return (
  //   <Box
  //     as="section"
  //     pt={{ base: "4", md: "8" }}
  //     pb={{ base: "12", md: "24" }}
  //     width="100%"
  //   >
  //     <Container>
  //       <Box
  //         bg="bg-surface"
  //         px={{ base: "4", md: "6" }}
  //         py="5"
  //         boxShadow={useColorModeValue("sm", "sm-dark")}
  //         borderTopWidth="4px"
  //         borderColor="accent"
  //       >
  //         <Stack spacing="1">
  //           <TrackDescription track={track} />
  //         </Stack>
  //         {progressions.map((ps, idx) => (
  //           <Stack
  //             key={idx}
  //             justify="space-between"
  //             direction="row"
  //             spacing="4"
  //             p="4"
  //           >
  //             <HStack spacing="1" fontSize="sm">
  //               {/* <Text title="occurances" fontWeight="bold" fontSize="2em">
  //                 {ps.values.length}
  //               </Text> */}
  //               <ShapedChordProgression progression={ps} key={ps.toString()} />
  //             </HStack>
  //           </Stack>
  //         ))}
  //       </Box>
  //     </Container>
  //   </Box>
  // );
};

export const TrackDescription = ({ track }: { track: Track }) => {
  return (
    <HStack>
      <Text fontSize="2xl" fontWeight="medium">
        {`Track ${track.channel}`}
      </Text>
      <InstrumentTag instrument={track.instrument} />
    </HStack>
  );
};

export const ShapedChordProgression = ({
  progression,
  size = "lg",
  includePlayback = true,
}: {
  progression: Progression;
  includePlayback?: boolean;
  size?: string;
}) => {
  const [chordProgressionPlaying, setChordProgressionPlaying] = useState(false);
  const playChordProgression = () => {
    if (chordProgressionPlaying === true) {
      Tone.Transport.stop();
      setChordProgressionPlaying(false);
      return;
    }
    setChordProgressionPlaying(true);
    Tone.start();
    // TODO use the closest instrument to the track's instrument
    const library = new InstrumentLibrary(DefaultInstrument);
    const sampler = library.samplerFor(DefaultInstrument).toDestination();

    const events = progression.chords.map((chord: Chord, index: number) => {
      const notesToPlay = chord.chord.notes;
      return { time: index, chord: notesToPlay };
    });
    const part = new Tone.Part((time, value) => {
      console.log("playing", { value, time });
      sampler.triggerAttackRelease(value.chord, "4n", time);
    }, events);
    part.start(0);
    console.log({ events, sampler, part });
    Tone.Transport.start();
  };
  return (
    <HStack>
      <Wrap>
        {progression.chords.map((c, sidx) => (
          <WrapItem key={sidx}>
            <ShapedChord size={size} chord={c} key={sidx} />
          </WrapItem>
        ))}
      </Wrap>
      <Spacer />
      {includePlayback && (
        <IconButton
          title="Play"
          aria-label="Play"
          icon={chordProgressionPlaying ? <FiStopCircle /> : <FiPlayCircle />}
          fontSize="2em"
          variant="ghost"
          onClick={playChordProgression}
        />
      )}
    </HStack>
  );
};

export const InstrumentTag = ({ instrument }: { instrument: Instrument }) => {
  const icon = () => {
    const f = instrument.family.toLowerCase();
    const n = instrument.name.toLowerCase();
    if (f.includes("piano")) {
      return MdPiano;
    } else if (f.includes("guitar")) {
      return n.includes("acoustic") ? FaGuitar : GiGuitar;
    } else if (f.includes("bass")) {
      return GiGuitarBassHead;
    } else if (f.includes("strings") || f.includes("ensemble")) {
      if (n.includes("harp")) {
        return GiHarp;
      } else if (n.includes("void")) {
        return IoMdMicrophone;
      } else {
        return GiViolin;
      }
    } else if (f.includes("brass")) {
      if (n.includes("trumpet")) {
        return GiTrumpet;
      } else if (n.includes("trombone")) {
        return GiTrombone;
      } else if (n.includes("tuba")) {
        return GiTuba;
      } else {
        return GiFrenchHorn;
      }
    } else if (f.includes("reed")) {
      if (n.includes("clarinet")) {
        return GiClarinet;
      } else if (n.includes("sax")) {
        return GiSaxophone;
      } else if (n.includes("bassoon")) {
        return GiBassoon;
      } else {
        return GiClarinet;
      }
    } else if (f.includes("pipe")) {
      if (n.includes("flute")) {
        return GiFlute;
      }
    } else if (n.includes("synth")) {
      return GiMusicalKeyboard;
    } else {
      return BsMusicNote;
    }
  };

  return (
    <Tag size="lg" colorScheme="cyan">
      <TagLeftIcon size="lg" as={icon()} />
      <TagLabel>{instrument.name}</TagLabel>
    </Tag>
  );
};

export const MidiStats = ({ xray }: { xray: XRay }) => {
  const timeSignature = xray.midi.header.timeSignatures[0]?.timeSignature;

  return (
    <HStack justifyContent="space-between">
      {xray.midi.header.keySignatures.length !== 0 && (
        <StatCard
          title="Key Signature"
          value={`${xray.midi.header.keySignatures[0].key} ${xray.midi.header.keySignatures[0].scale}`}
          icon={<BsMusicNoteList />}
        />
      )}
      {timeSignature != null && (
        <StatCard
          title="Time Signature"
          value={`${timeSignature[0]}/${timeSignature[1]}`}
          icon={<CgMathPercent />}
        />
      )}
      {xray.midi.header.tempos.length !== 0 && (
        <StatCard
          title="Tempo"
          value={`${Math.round(xray.midi.header.tempos[0].bpm)}`}
          icon={<GiMetronome />}
        />
      )}
      <StatCard
        title="Duration"
        value={`${new Date(xray.midi.duration * 1000)
          .toISOString()
          .substring(14, 19)}`}
        icon={<ImHourGlass />}
      />
      <StatCard
        title={xray.midi.tracks.length === 1 ? "Track" : "Tracks"}
        value={`${xray.midi.tracks.length}`}
        icon={<AiOutlineInsertRowLeft />}
      />
    </HStack>
  );
};

interface StatCardProps extends FlexProps {
  title: string;
  icon: React.ReactElement;
  value: string;
}
export const StatCard = (props: StatCardProps) => {
  const { value, title, icon } = props;
  return (
    <Flex
      bg={useColorModeValue("blue.50", "blue.300")}
      p="3"
      rounded="2xl"
      {...props}
    >
      <Box
        alignItems="baseline"
        flex="1"
        mr="4"
        color={useColorModeValue("blue.500", "inherit")}
      >
        <Text fontSize="xl" fontWeight="extrabold" mb="4" lineHeight="1">
          {value}
        </Text>
        <Text color={useColorModeValue("gray.900", "white")}>{title}</Text>
      </Box>
      <Box fontSize="2rem" color={useColorModeValue("blue.100", "blue.500")}>
        {icon}
      </Box>
    </Flex>
  );
};

export const midiNoteToNote = (mn: MidiNote) => {
  const octave = Number(mn.name.charAt(mn.name.length - 1));
  return new OldNote(pitchFromString(mn.name), new Octave(octave));
};

export const midiTrackIsPercussion = (track: MidiTrack) => {
  return track.instrument.percussion;
};

export const midiNoteTimeAndTicks = (note: MidiNote) => {
  return `${note.ticks}:${note.durationTicks}`;
};

export class MidiChord {
  chord: Chord;
  midiNotes: MidiNote[];
  measure: number;
  ticks: number;
  id: string;

  constructor(chord: Chord, midiNotes: MidiNote[]) {
    this.chord = chord;
    this.midiNotes = midiNotes;
    const note = midiNotes[0];
    this.measure = Math.floor(note.bars);
    this.ticks = note.ticks;
    this.id = chord.chord.name;
  }
}

export class MidiChordProgression {
  chords: MidiChord[];
  id: string;

  constructor(chords: MidiChord[]) {
    this.chords = chords;
    this.id = this.toString();
  }

  allTheSameChord() {
    const set = new Set();
    this.chords.forEach((c) => set.add(c.id));

    return set.size === 1;
  }

  toString() {
    return this.chords.map((c) => c.chord.toString()).join(" ");
  }
}

export class XRay {
  midi: Midi;
  private midiNotesForTrackByTime: Record<number, Record<string, MidiNote[]>> =
    {};
  private midiChordsByTrack: Record<number, MidiChord[]> = {};
  private progressionsByTrack: Record<number, Progression[]> = {};
  chordCounts: Record<string, number> = {};

  constructor(midi: Midi) {
    this.midi = midi;
    // this.chordsByTrack = {};
    // this.progressionsByTrack = {};

    this.midi.tracks
      .filter((t) => !midiTrackIsPercussion(t))
      .forEach((t) => {
        const chordsByMeasure = this.getChordsForTrackByMeasure(t);
        if (_.size(chordsByMeasure) > 0) {
          console.log("chords for track by measure", {
            track: t.channel,
            chordsByMeasure,
          });
        }

        const progressions = this.getChordProgressionsForTrack(t);
        if (progressions.length > 0) {
          console.log({ track: t.channel, progressions });
        }
        // const progressionCounts = groupBy(progressions, (p) => p.name);
        // if (Object.keys(progressionCounts).length > 0) {
        //   console.log("progression counts", {
        //     track: t,
        //     progressions,
        //     progressionCounts,
        //   });
        // }
      });
    // console.log({ progressionsByTrack: this.progressionsByTrack });

    //   this.progressionsByTrack[Number(n)] = progressions;
    // });

    // Object.values(this.progressionsByTrack).forEach((ps) => {
    //   ps.sort((lhs, rhs) => (lhs.chords.length < rhs.chords.length ? 1 : -1));
    // });
    console.log({ chordCounts: this.chordCounts });
  }

  track(number: number) {
    return this.midi.tracks.find((t) => t.channel === number);
  }

  chordRank(name: string) {
    const count = this.chordCounts[name];
    if (count == null) {
      return 0.0;
    }
    const allCounts = Object.values(this.chordCounts).sort((a, b) => a - b);
    const [min, max] = [allCounts[0], allCounts[allCounts.length - 1]];

    const rank = Math.max((count - min) / (max - min), 0.3);
    // console.log("non-zero chord rank", {
    //   rank,
    //   name,
    //   count,
    //   min,
    //   max,
    //   chordCounts: this.chordCounts,
    // });
    return rank;
  }

  getChordsForTrack(track: MidiTrack) {
    const chords = this.midiChordsByTrack[track.channel] ?? [];

    if (chords.length > 0) {
      return chords;
    }

    const notesByTime = this.getMidiNotesForTrackByTime(track);
    const notesInTimeOrder = Object.keys(notesByTime).sort();

    let identified = 0;
    let notIdentified = 0;
    notesInTimeOrder.forEach((key) => {
      const n = notesByTime[key];
      if (n.length < 3) {
        return;
      }

      const notes = n.map((mn) => Note.fromMidi(mn.midi));
      const possibleChords = Chord.detect(notes);

      if (possibleChords.length > 0) {
        identified++;
        chords.push(new MidiChord(possibleChords[0], n));
      } else {
        notIdentified++;
        console.warn("could not identify chord from notes", { notes });
      }
    });

    if (identified > 0 || notIdentified > 0) {
      console.log("chord identification summary", {
        hitRate: Math.round((identified / (notIdentified + identified)) * 100),
        identified,
        notIdentified,
      });
    }

    this.midiChordsByTrack[track.channel] = chords;

    chords.forEach((midiChord) => {
      const name = midiChord.chord.chord.symbol;
      let count = this.chordCounts[name];
      if (count == null) {
        count = 0;
      }
      count++;
      this.chordCounts[name] = count;
    });

    return chords;
  }

  getChordsForTrackByMeasure(track: MidiTrack) {
    const chords = this.getChordsForTrack(track);
    const chordsByMeasure: Record<number, MidiChord[]> = {};

    chords.forEach((c) => {
      let chordsForMeasure = chordsByMeasure[c.measure];
      if (chordsForMeasure == null) {
        chordsForMeasure = [];
      }

      chordsForMeasure.push(c);
      chordsForMeasure.sort((lhs, rhs) => lhs.ticks - rhs.ticks);
      chordsByMeasure[c.measure] = chordsForMeasure;
    });

    return chordsByMeasure;
  }

  getChordProgressionsForTrack(track: MidiTrack) {
    let progressions = this.progressionsByTrack[track.channel] ?? [];

    if (progressions.length > 0) {
      return progressions;
    }

    const chordsByMeasure = this.getChordsForTrackByMeasure(track);
    const measuresInOrder = Object.keys(chordsByMeasure)
      .map((k) => parseInt(k))
      .sort((a, b) => a - b);

    progressions = measuresInOrder
      .map((m) => new Progression(chordsByMeasure[m].map((mc) => mc.chord)))
      .filter((p) => p.chords.length > 1);

    this.progressionsByTrack[track.channel] = progressions;
    return progressions;
  }

  getMidiNotesForTrackByTime(track: MidiTrack) {
    const notesByTime = this.midiNotesForTrackByTime[track.channel] ?? {};
    if (_.size(notesByTime) > 0) {
      return notesByTime;
    }

    track.notes.forEach((n) => {
      const timeAndTicks = midiNoteTimeAndTicks(n);
      let values = notesByTime[timeAndTicks];
      if (values == null) {
        values = [];
      }

      values.push(n);
      notesByTime[timeAndTicks] = values;
    });

    this.midiNotesForTrackByTime[track.channel] = notesByTime;

    return notesByTime;
  }

  chords() {
    return this.midiChordsByTrack;
  }

  progressions() {
    return this.progressionsByTrack;
  }
}

function groupBy<T>(array: T[], f: (e: T) => string) {
  const grouped = {} as Record<string, T[]>;

  array.forEach((e) => {
    const key = f(e);

    let group = grouped[key];
    if (group == null) {
      group = [];
    }
    group.push(e);
    grouped[key] = group;
  });

  return grouped;
}
