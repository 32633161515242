import { Heading, Box } from "@chakra-ui/react";
import _ from "lodash";
import { Chord } from "../Note";

export const Sandbox = () => {
  return (
    <Box>
      <Heading>Sandbox</Heading>
      <PianoKeyboard width={900} />
    </Box>
  );
};

// Percent size of black relative to white. The calculation uses the mm size of
// each.
const blackKeyWidthRelativeToWhite = 13.7 / 23.5;
const blackKeyHeightRelativeToWhite = 9 / 15;
const heightRelativeToWidth = 400 / 1012;

export const PianoKeyboard = ({ width }: { width: number }) => {
  const height = width * heightRelativeToWidth;
  // TODO temporarily hardcoded, will be determined baed on the range of notes provided as a prop
  const numberOfKeys = 11;
  const whiteKeyWidth = width / numberOfKeys;
  const blackKeyWidth = whiteKeyWidth * blackKeyWidthRelativeToWhite;
  const whiteKeyHeight = height;
  const blackKeyHeight = height * blackKeyHeightRelativeToWhite;
  const chord = new Chord("CM7");
  // console.log("RANGE", {
  //   chord,
  //   chromaticRange: Tonal.Range.chromatic(
  //     chord.chord.notes.map((n) => n + "4")
  //   ),
  // });

  return (
    <svg viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
      <g>
        {_.times(numberOfKeys, (n) => (
          <WhitePianoKey
            x={whiteKeyWidth * n}
            width={whiteKeyWidth}
            height={whiteKeyHeight}
          />
        ))}

        <BlackPianoKey
          x={whiteKeyWidth * 1 - blackKeyWidth / 2}
          width={blackKeyWidth}
          height={blackKeyHeight}
        />
        <BlackPianoKey
          x={whiteKeyWidth * 2 - blackKeyWidth / 2}
          width={blackKeyWidth}
          height={blackKeyHeight}
        />
        <BlackPianoKey
          x={whiteKeyWidth * 3 - blackKeyWidth / 2}
          width={blackKeyWidth}
          height={blackKeyHeight}
        />
        <BlackPianoKey
          x={whiteKeyWidth * 5 - blackKeyWidth / 2}
          width={blackKeyWidth}
          height={blackKeyHeight}
        />
        <BlackPianoKey
          x={whiteKeyWidth * 6 - blackKeyWidth / 2}
          width={blackKeyWidth}
          height={blackKeyHeight}
        />
        <BlackPianoKey
          x={whiteKeyWidth * 8 - blackKeyWidth / 2}
          width={blackKeyWidth}
          height={blackKeyHeight}
        />
        <BlackPianoKey
          x={whiteKeyWidth * 9 - blackKeyWidth / 2}
          width={blackKeyWidth}
          height={blackKeyHeight}
        />
        <BlackPianoKey
          x={whiteKeyWidth * 10 - blackKeyWidth / 2}
          width={blackKeyWidth}
          height={blackKeyHeight}
        />
        <circle
          cx={(whiteKeyWidth / 2) * 15}
          cy={height * 0.8}
          stroke="white"
          fill="red"
          r={whiteKeyWidth * 0.4}
        />
        <text
          x={(whiteKeyWidth / 2) * 15}
          y={height * 0.8}
          textAnchor="middle"
          alignmentBaseline="middle"
          fontSize="1.5rem"
          fill="white"
        >
          F
        </text>
      </g>
    </svg>
  );
};

export type PianoKeyType = "black" | "white";

export type PianoKeyProps = {
  keyType: PianoKeyType;
  width: number;
  height: number;
  x?: number;
  y?: number;
};

const PianoKey = ({ keyType, width, height, x = 0, y = 0 }: PianoKeyProps) => {
  return (
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      fill={keyType === "white" ? "rgb(232,232,232)" : "rgb(46,46,46)"}
      strokeWidth="1"
      stroke="rgb(0,0,0)"
    />
  );
};

export const WhitePianoKey = (props: Omit<PianoKeyProps, "keyType">) => {
  return <PianoKey keyType="white" {...props} />;
};

export const BlackPianoKey = (props: Omit<PianoKeyProps, "keyType">) => {
  return <PianoKey keyType="black" {...props} />;
};
