import * as PitchInterval from "./PitchInterval";
import * as Tonal from "@tonaljs/tonal";
import { Note as TonalNote } from "@tonaljs/core";
import { Chord as TonalChord } from "@tonaljs/chord";
import { stringify } from "querystring";

export enum Accidental {
  Natural = "♮",
  Sharp = "♯",
  Flat = "♭",
  DoubleSharp = "♯♯",
  DoubleFlat = "♭♭",
}

export type SemiTone = number;

const SemiToneCents = 1200;
const SemiTonesPerOctave = 12;

// Example:
//  lhs = 130.81 (C3)
//  rhs = 261.63 (C4)
//  intervalBetweenFrequencies(lhs, rhs) => SemiTone(12)
export function intervalBetweenFrequencies(lhs: Hertz, rhs: Hertz): SemiTone {
  return Math.round(
    (SemiToneCents * Frequency.logarithmicRatio(lhs, rhs)) / 100
  );
}

type Hertz = number;

export type NoteLetter = "A" | "B" | "C" | "D" | "E" | "F" | "G";

export const ConcertPitch = 440.0;

export class CircularPitchClass {
  pitches: Pitch[];
  indexes: Record<string, number>;

  constructor(pitches: Pitch[]) {
    this.pitches = pitches;
    this.indexes = {};

    pitches.forEach((value, index) => {
      this.indexes[value.toString()] = index;
    });
  }

  moveUpFrom(pitch: Pitch, semiTone: SemiTone): Pitch {
    const index = this.indexOf(pitch);
    const indexToMoveTo = (index + semiTone) % SemiTonesPerOctave;
    return this.pitches[indexToMoveTo];
  }

  moveDownFrom(pitch: Pitch, semiTone: SemiTone): Pitch {
    const index = this.indexOf(pitch);
    const indexToMoveTo = (index - semiTone) % SemiTonesPerOctave;
    const destination =
      indexToMoveTo < 0 ? this.pitches.length + indexToMoveTo : indexToMoveTo;
    return this.pitches[destination];
  }

  moveFrom(pitch: Pitch, semiTone: SemiTone): Pitch {
    if (semiTone == 0) {
      return pitch;
    }

    if (semiTone > 0) {
      return this.moveUpFrom(pitch, semiTone);
    }

    return this.moveDownFrom(pitch, -semiTone);
  }

  indexOf(pitch: Pitch): number {
    const key = pitch.toString();
    switch (pitch.accidental) {
      case Accidental.Flat: {
        const natural = pitch.natural();
        const index = this.indexes[natural.toString()];
        if (index != 0) {
          return index - 1;
        } else {
          return this.indexOf(Pitch.B);
        }
      }
      default: {
        return this.indexes[key];
      }
    }
  }
}

export function pitchFromString(value: string): Pitch {
  const noteLetter = value[0] as NoteLetter;
  if (value.length == 1) {
    return new Pitch(noteLetter);
  }

  switch (value[1]) {
    case "♯":
    case "#": {
      return new Pitch(noteLetter, Accidental.Sharp);
    }
    case "♭":
    case "b": {
      return new Pitch(noteLetter, Accidental.Flat);
    }
    default: {
      return new Pitch(noteLetter);
    }
  }
}

export class Pitch {
  static A = new Pitch("A");
  static B = new Pitch("B");
  static C = new Pitch("C");
  static D = new Pitch("D");
  static E = new Pitch("E");
  static F = new Pitch("F");
  static G = new Pitch("G");

  letter: NoteLetter = "C";
  accidental: Accidental = Accidental.Natural;

  constructor(letter: NoteLetter, accidental: Accidental = Accidental.Natural) {
    this.letter = letter;
    this.accidental = accidental;
  }

  isSharp() {
    return this.accidental === Accidental.Sharp;
  }

  isFlat() {
    return this.accidental === Accidental.Flat;
  }

  isNatural() {
    return this.accidental === Accidental.Natural;
  }

  natural(): Pitch {
    return new Pitch(this.letter, Accidental.Natural);
  }

  sharp(): Pitch {
    return new Pitch(this.letter, Accidental.Sharp);
  }

  flat(): Pitch {
    return new Pitch(this.letter, Accidental.Flat);
  }

  flatten(): Pitch {
    return this.up(1).flat();
  }

  toString(): string {
    switch (this.accidental) {
      case Accidental.Sharp: {
        return this.letter + "#";
      }
      case Accidental.Flat: {
        return this.letter + "b";
      }
      default: {
        return this.letter;
      }
    }
  }

  atOctave(octave: Octave): OldNote {
    return new OldNote(this, octave);
  }

  up(semiTone: SemiTone): Pitch {
    return PitchClass.moveUpFrom(this, semiTone);
  }

  down(semiTone: SemiTone): Pitch {
    return PitchClass.moveDownFrom(this, semiTone);
  }
}

export const middle = (p: Pitch) => {
  return p.atOctave(new Octave(4));
};

type Quality =
  | "major"
  | "minor"
  | "diminished"
  | "augmented"
  | "half-diminished"
  | "dominant"
  | "suspended";

export class IdentifiedChord {
  chord: OldChord;
  interval: ChordInterval;

  constructor(chord: OldChord, interval: ChordInterval) {
    this.chord = chord;
    this.interval = interval;
    if (
      this.interval.quality === "major" &&
      this.chord.notes[0].pitch.isSharp()
    ) {
      this.chord.notes[0] = this.chord.notes[0].flatten();
    }
  }

  qualitySymbol() {
    let symbol: string;
    switch (this.interval.quality) {
      case "minor":
        symbol = "m";
        break;
      case "diminished":
        symbol = "˚";
        break;
      case "augmented":
        symbol = "aug";
        break;
      default:
        symbol = "";
    }

    return symbol;
  }

  toString() {
    return this.chord.notes[0].pitch.toString() + this.qualitySymbol();
  }
}

export class Chord {
  chord: TonalChord;

  static detect(notes: Note[]) {
    const pitchClasses = notes.map((n) => n.note.pc);
    const permutations = Tonal.Collection.permutations(pitchClasses);
    const matches: Record<string, Chord> = {};
    for (const permutation of permutations) {
      const chords = Tonal.Chord.detect(permutation)
        .map((chordName) => new Chord(chordName))
        .filter((chord) => chord.chord.name !== "");
      if (chords.length > 0) {
        chords.forEach((chord) => {
          matches[chord.chord.symbol] = chord;
        });
      }
    }

    // We sort by how long the symbol is, where we go off the general assumption
    // that shorter symbols (e.g. BM vs Ebm#5) will put the more common chords
    // first
    const matchesInOrder = Object.keys(matches).sort(
      (a, b) => a.length - b.length
    );

    return matchesInOrder.map((symbol) => matches[symbol]);
  }

  constructor(src: string | [string, string]) {
    this.chord = Tonal.Chord.get(src);
  }

  reduced() {
    return Tonal.Chord.reduced(this.chord.symbol);
  }

  simpleName() {
    return this.chord.symbol.replace("M", "");
  }

  label() {
    return this.chord.symbol.replace("M", "");
    // let label = this.chord.tonic;

    // if (this.chord.quality === "Minor") {
    //   label = label + "m";
    // }

    // if (this.chord.name.includes("suspended")) {
    //   return this.chord.symbol;
    // }

    // if (this.chord.quality === "Diminished") {
    //   label = label + "˚";
    // }

    // if (this.chord.name.includes("seventh")) {
    //   label = label + "7";
    // }

    // return label ?? this.chord.symbol;
  }
}

type Inversion = 0 | 1 | 2;
export class OldChord {
  name: string;
  notes: OldNote[];
  id: string;

  constructor(notes: OldNote[]) {
    this.notes = notes;
    this.name = notes[0].pitch.toString();
    this.id = this.toString();
  }

  inversion(number: Inversion) {
    // TODO needs to have any extra notes stripped out
    switch (number) {
      case 0:
        return this;
      case 1:
        // TODO make generic so it handles both triads and sevenths
        // 3 5 1
        return new OldChord([this.notes[1], this.notes[2], this.notes[0]]);
      case 2:
        // 5 1 3
        return new OldChord([this.notes[2], this.notes[0], this.notes[1]]);
    }
  }

  rootNote() {
    return this.identifyChord()?.chord.notes[0];
  }

  copy() {
    return new OldChord(this.notes);
  }

  removeRedundantNotes() {
    const uniqueNotes = new Set<string>();
    const notes: OldNote[] = [];
    this.notes.forEach((n) => {
      const name = n.pitch.toString();
      if (!uniqueNotes.has(name)) {
        notes.push(n);
      }
      uniqueNotes.add(name);
    });

    if (notes.length !== this.notes.length) {
      console.log("REMOVED NOTES", { original: this.notes, notes });
    }

    return new OldChord(notes);
  }

  identifyChord() {
    const normalized = this.removeRedundantNotes();
    if (normalized.notes.length === 3) {
      return normalized.identifyChordFromIntervals(TriadIntervals);
    } else if (normalized.notes.length === 4) {
      return normalized.identifyChordFromIntervals(SeventhChordIntervals);
    }
  }

  identifyChordFromIntervals(intervals: ChordInterval[]) {
    let found: IdentifiedChord | null = null;
    for (const note of this.notes) {
      if (found != null) {
        break;
      }
      for (const interval of intervals) {
        const notes = interval.from(note);
        if (notes.length === 0) {
          console.warn("no notes from interval", { interval, note });
          continue;
        }
        const chord = new OldChord(notes);
        if (
          this.samePitchesAs(chord) ||
          this.samePitchesAs(chord.inversion(1)) ||
          this.samePitchesAs(chord.inversion(2))
        ) {
          found = new IdentifiedChord(chord, interval);
          break;
        }
      }

      return found;
    }
  }

  samePitchesAs(otherChord: OldChord) {
    const id = (c: OldChord) =>
      c.notes
        .map((n) => n?.pitch.toString())
        .sort()
        .join("");

    return id(this) === id(otherChord);
  }

  changeOctave(octave: number) {
    this.notes.forEach((n) => (n.octave.value = octave));
  }

  toString() {
    return this.notes
      .map((n) => n?.toString())
      .sort((lhs, rhs) =>
        lhs.split("").reverse().join("") < rhs.split("").reverse().join("")
          ? -1
          : 1
      )
      .join("-");
  }
}
export class OldChords {
  values: OldChord[];

  constructor(chords: OldChord[]) {
    this.values = chords;
  }

  groupBy(f: (chord: OldChord) => string): Record<string, OldChord[]> {
    const groups: Record<string, OldChord[]> = {};

    this.values.forEach((c) => {
      const key = f(c);
      groups[key].push(c);
    });

    return groups;
  }
}

export class Progression {
  chords: Chord[];

  constructor(chords: Chord[]) {
    this.chords = chords.filter(
      (chord, idx) =>
        idx === 0 || chord.chord.symbol !== chords[idx - 1].chord.symbol
    );
  }

  romanNumerals(key: string) {
    return Tonal.Progression.toRomanNumerals(
      key,
      this.chords.map((c) => c.chord.symbol)
    );
  }

  toString() {
    return this.chords.map((chord) => chord.chord.symbol).join(" ");
  }
}
export class OldChordProgression {
  name: string;
  chords: OldChords;

  constructor(chords: OldChords) {
    this.chords = chords;
    this.name = this.toString();
  }

  toString() {
    return this.chords.values.map((c) => c.toString()).join(" ");
  }

  allTheSameChord() {
    const set = new Set();
    this.chords.values.forEach((c) => set.add(c.toString()));

    return set.size === 1;
  }
}

export class ChordProgressions {
  values: OldChordProgression[];

  constructor(progressions: OldChordProgression[]) {
    this.values = progressions;
  }
}

export interface Interval {
  semiTones: SemiTone[];

  from(note: OldNote): OldNote[];
}

export class AbsoluteInterval implements Interval {
  semiTones: SemiTone[];

  constructor(semiTones: SemiTone[]) {
    this.semiTones = semiTones;
  }

  from(note: OldNote): OldNote[] {
    const notes: OldNote[] = [note];
    this.semiTones.forEach((semiTone: SemiTone) => {
      notes.push(note.moveUp(semiTone));
    });

    return notes;
  }
}

export class StackedInterval implements Interval {
  semiTones: SemiTone[];

  constructor(semiTones: SemiTone[]) {
    this.semiTones = semiTones;
  }

  from(note: OldNote): OldNote[] {
    const notes: OldNote[] = [note];
    this.semiTones.forEach((semiTone: SemiTone) => {
      const next = notes[notes.length - 1].moveUp(semiTone);
      notes.push(next);
    });

    return notes;
  }
}

export class ChordInterval {
  quality: Quality;
  interval: Interval;

  constructor(quality: Quality, interval: Interval) {
    this.interval = interval;
    this.quality = quality;
  }

  from(note: OldNote) {
    return this.interval.from(note);
  }
}

export const MajorTriad = new ChordInterval(
  "major",
  new StackedInterval([
    PitchInterval.majorThird, // 4 semitones from root
    PitchInterval.minorThird, // perfect fifth, 7 semitones from root
  ])
);

export const MinorTriad = new ChordInterval(
  "minor",
  new StackedInterval([
    PitchInterval.minorThird, // 3 semitones from root
    PitchInterval.majorThird, // perfect fifth, 7 semitones from root
  ])
);

export const DiminishedTriad = new ChordInterval(
  "diminished",
  new AbsoluteInterval([
    PitchInterval.minorThird,
    PitchInterval.diminishedFifth,
  ])
);

export const AugmentedTriad = new ChordInterval(
  "augmented",
  new AbsoluteInterval([PitchInterval.majorThird, PitchInterval.augmentedFifth])
);

export const SuspendedSecond = new ChordInterval(
  "suspended",
  new AbsoluteInterval([PitchInterval.wholeTone, PitchInterval.perfectFifth])
);

export const SuspendedFourth = new ChordInterval(
  "suspended",
  new AbsoluteInterval([
    PitchInterval.perfectFourth,
    PitchInterval.perfectFifth,
  ])
);

export const TriadIntervals = [
  MajorTriad,
  MinorTriad,
  DiminishedTriad,
  AugmentedTriad,
  SuspendedSecond,
  SuspendedFourth,
];

export const MajorSeventh = new ChordInterval(
  "major",
  new AbsoluteInterval([
    PitchInterval.majorThird,
    PitchInterval.perfectFifth,
    PitchInterval.majorSeventh,
  ])
);

export const MinorSeventh = new ChordInterval(
  "minor",
  new AbsoluteInterval([
    PitchInterval.minorThird,
    PitchInterval.perfectFifth,
    PitchInterval.minorSeventh,
  ])
);

export const MinorMajorSeventh = new ChordInterval(
  "minor",
  new AbsoluteInterval([
    PitchInterval.minorThird,
    PitchInterval.perfectFifth,
    PitchInterval.majorSeventh,
  ])
);

export const DominantSeventh = new ChordInterval(
  "dominant",
  new AbsoluteInterval([
    PitchInterval.majorThird,
    PitchInterval.perfectFifth,
    PitchInterval.minorSeventh,
  ])
);

export const DiminishedSeventh = new ChordInterval(
  "diminished",
  new AbsoluteInterval([
    PitchInterval.minorThird,
    PitchInterval.diminishedFifth,
    PitchInterval.diminishedSeventh,
  ])
);

export const HalfDiminishedSeventh = new ChordInterval(
  "half-diminished",
  new AbsoluteInterval([
    PitchInterval.minorThird,
    PitchInterval.diminishedFifth,
    PitchInterval.minorSeventh,
  ])
);

export const AugmentedMajorSeventh = new ChordInterval(
  "major",
  new AbsoluteInterval([
    PitchInterval.majorThird,
    PitchInterval.augmentedFifth,
    PitchInterval.majorSeventh,
  ])
);

export const AugmentedSeventh = new ChordInterval(
  "dominant",
  new AbsoluteInterval([
    PitchInterval.majorThird,
    PitchInterval.augmentedFifth,
    PitchInterval.minorSeventh,
  ])
);

export const SeventhFlatFive = new ChordInterval(
  "dominant",
  new AbsoluteInterval([
    PitchInterval.majorThird,
    PitchInterval.diminishedFifth,
    PitchInterval.minorSeventh,
  ])
);

export const SeventhChordIntervals = [
  MajorSeventh,
  DominantSeventh,
  MinorSeventh,
  DiminishedSeventh,
  HalfDiminishedSeventh,
  MinorMajorSeventh,
  AugmentedMajorSeventh,
  AugmentedSeventh,
  SeventhFlatFive,
];

export const PitchClass = new CircularPitchClass([
  Pitch.C,
  Pitch.C.sharp(),
  Pitch.D,
  Pitch.D.sharp(),
  Pitch.E,
  Pitch.F,
  Pitch.F.sharp(),
  Pitch.G,
  Pitch.G.sharp(),
  Pitch.A,
  Pitch.A.sharp(),
  Pitch.B,
]);

export class Octave {
  static min = 0;
  static max = 8;
  static twelfthRootOf2 = Math.pow(2, 1 / 12.0);

  value: number;

  constructor(value: number) {
    console.assert(value >= Octave.min && value <= Octave.max);

    this.value = value;
  }

  interval(octave: Octave): Octave {
    return new Octave(this.value - octave.value);
  }
}

export class Frequency {
  static logarithmicRatio(lhs: Hertz, rhs: Hertz): number {
    return Math.log(rhs / lhs) / Frequency.naturalLogarithm;
  }

  static naturalLogarithm = Math.log(2);
}

export class Note {
  note: TonalNote | Tonal.NoNote;

  static fromMidi(midi: number) {
    return new Note(Tonal.Note.fromMidi(midi));
  }

  constructor(name: Tonal.NoteLiteral) {
    this.note = Tonal.Note.get(name);
  }
}

export class OldNote {
  pitch: Pitch;
  octave: Octave;
  pitchSpaceIndex: number;

  constructor(pitch: Pitch, octave: Octave = new Octave(3)) {
    this.pitch = pitch;
    this.octave = octave;
    this.pitchSpaceIndex =
      PitchClass.indexOf(pitch) + octave.value * SemiTonesPerOctave;
  }

  moveUp(semiTone: SemiTone): OldNote {
    return PitchSpace[this.pitchSpaceIndex + semiTone];
  }

  chord(interval: Interval): OldNote[] {
    return interval.from(this);
  }

  flatten() {
    return new OldNote(this.pitch.flatten(), this.octave);
  }

  toString(): string {
    return `${this.pitch.toString()}${this.octave.value}`;
  }
}

const PitchSpace = (() => {
  const notes: OldNote[] = [];
  for (let i = Octave.min; i < Octave.max; i++) {
    PitchClass.pitches.forEach((p) => {
      notes.push(new OldNote(p, new Octave(i)));
    });
  }

  return notes;
})();

// TODO port from Scala
// type Key Pitch

// func (k Key) Pitch() Pitch { return Pitch(k) }
// func (k Key) IV() Pitch    { return k.Pitch().MoveDownFrom(PerfectFifth) }
// func (k Key) I() Pitch     { return Pitch(k) }
// func (k Key) V() Pitch     { return k.Pitch().MoveUpFrom(PerfectFifth) }

// // N.B. II - VII should be lower case in accordance with proper musical
// // notation but we want these methods to be exported to we have capitalized
// // them
// func (k Key) II() Pitch  { return k.V().MoveUpFrom(PerfectFifth) }
// func (k Key) VI() Pitch  { return k.II().MoveUpFrom(PerfectFifth) }
// func (k Key) III() Pitch { return k.VI().MoveUpFrom(PerfectFifth) }
// func (k Key) VII() Pitch { return k.III().MoveUpFrom(PerfectFifth) }

// func (k Key) Major() Pitches      { return Pitches{k.IV(), k.I(), k.V()} }
// func (k Key) Minor() Pitches      { return Pitches{k.II(), k.VI(), k.III()} }
// func (k Key) Diminished() Pitches { return Pitches{k.VII()} }
