import circleOfFifthsNotes from "./../data/circle-of-fifths-notes.json";
import { ColorWheel, ColorWheelData, ColorWheelNode } from "./ColorWheel";
import { Chord } from "./../Note";
import { VStack } from "@chakra-ui/react";

interface Props {
  size?: number;
  addChord?: (chord: Chord) => void;
  tonic: string;
  nodeProps?: (data: ColorWheelData) => React.ComponentPropsWithoutRef<"g">;
}

export const CircleOfFifths = (props: Props) => {
  const {
    tonic,
    addChord = () => {
      // do nothing
    },
    nodeProps = () => {
      // do nothing
      return {};
    },
    size,
  } = props;

  return (
    <VStack>
      <ColorWheel
        tonic={tonic}
        size={size || 800}
        data={circleOfFifthsNotes as ColorWheelData}
        nodeProps={nodeProps}
        onSegmentClick={(node: ColorWheelNode) => {
          const chord = new Chord(node.data.symbol);
          console.log("added chord", { chord });
          addChord(chord);
        }}
      />
    </VStack>
  );
};

export interface KeyLabel {
  key: string;
  numeral: string;
}
