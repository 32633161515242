import { ColorMusicColor } from "./Colors";
export type Mode =
  | "ionian"
  | "dorian"
  | "phrygian"
  | "lydian"
  | "mixolydian"
  | "aeolian"
  | "locrian";

export const ScaleDegreesByMode: Record<Mode, ScaleDegree[]> = {
  ionian: [
    { key: "C", numeral: "I", number: "1", shape: "square", color: "red" },
    {
      key: "D",
      numeral: "ii",
      number: "2",
      shape: "square",
      color: "red-orange",
    },
    { key: "E", numeral: "iii", number: "3", shape: "square", color: "yellow" },
    {
      key: "F",
      numeral: "IV",
      number: "4",
      shape: "circle",
      color: "purple-red",
    },
    { key: "G", numeral: "V", number: "5", shape: "circle", color: "orange" },
    {
      key: "A",
      numeral: "vi",
      number: "6",
      shape: "circle",
      color: "orange-yellow",
    },
    {
      key: "B",
      numeral: "vii˚",
      number: "7",
      shape: "circle",
      color: "yellow-green",
    },
  ],
  dorian: [
    { key: "C", numeral: "i", number: "1", shape: "square", color: "red" },
    {
      key: "D",
      numeral: "ii",
      number: "2",
      shape: "square",
      color: "red-orange",
    },
    {
      key: "E♭",
      numeral: "♭III",
      number: "♭3",
      shape: "circle",
      color: "blue-purple",
    },
    {
      key: "F",
      numeral: "IV",
      number: "4",
      shape: "circle",
      color: "purple-red",
    },
    { key: "G", numeral: "v", number: "5", shape: "circle", color: "orange" },
    {
      key: "A",
      numeral: "vi˚",
      number: "6",
      shape: "circle",
      color: "orange-yellow",
    },
    {
      key: "B♭",
      numeral: "♭VII",
      number: "♭7",
      shape: "square",
      color: "purple",
    },
  ],
  phrygian: [
    { key: "C", numeral: "i", number: "1", shape: "square", color: "red" },
    {
      key: "D♭",
      numeral: "♭II",
      number: "♭2",
      shape: "circle",
      color: "green-blue",
    },
    {
      key: "E♭",
      numeral: "♭III",
      number: "♭3",
      shape: "circle",
      color: "blue-purple",
    },
    {
      key: "F",
      numeral: "iv",
      number: "4",
      shape: "circle",
      color: "purple-red",
    },
    { key: "G", numeral: "v˚", number: "5", shape: "circle", color: "orange" },
    { key: "A♭", numeral: "♭VI", number: "♭6", shape: "square", color: "blue" },
    {
      key: "B♭",
      numeral: "♭vii",
      number: "♭7",
      shape: "square",
      color: "purple",
    },
  ],
  lydian: [
    { key: "C", numeral: "I", number: "1", shape: "square", color: "red" },
    {
      key: "D",
      numeral: "II",
      number: "2",
      shape: "square",
      color: "red-orange",
    },
    { key: "E", numeral: "iii", number: "3", shape: "square", color: "yellow" },
    {
      key: "F♯",
      numeral: "♯iv˚",
      number: "♯4",
      shape: "square",
      color: "green",
    },
    { key: "G", numeral: "V", number: "5", shape: "circle", color: "orange" },
    {
      key: "A",
      numeral: "vi",
      number: "6",
      shape: "circle",
      color: "orange-yellow",
    },
    {
      key: "B",
      numeral: "vii",
      number: "7",
      shape: "circle",
      color: "yellow-green",
    },
  ],
  mixolydian: [
    { key: "C", numeral: "I", number: "1", shape: "square", color: "red" },
    {
      key: "D",
      numeral: "ii",
      number: "2",
      shape: "square",
      color: "red-orange",
    },
    {
      key: "E",
      numeral: "iii˚",
      number: "3",
      shape: "square",
      color: "yellow",
    },
    {
      key: "F",
      numeral: "IV",
      number: "4",
      shape: "circle",
      color: "purple-red",
    },
    { key: "G", numeral: "v", number: "5", shape: "circle", color: "orange" },
    {
      key: "A",
      numeral: "vi",
      number: "6",
      shape: "circle",
      color: "orange-yellow",
    },
    {
      key: "B♭",
      numeral: "♭VII",
      number: "♭7",
      shape: "square",
      color: "purple",
    },
  ],
  aeolian: [
    { key: "C", numeral: "i", number: "1", shape: "square", color: "red" },
    {
      key: "D",
      numeral: "ii˚",
      number: "2",
      shape: "square",
      color: "red-orange",
    },
    {
      key: "E♭",
      numeral: "♭III",
      number: "♭3",
      shape: "circle",
      color: "blue-purple",
    },
    {
      key: "F",
      numeral: "iv",
      number: "4",
      shape: "circle",
      color: "purple-red",
    },
    { key: "G", numeral: "v", number: "5", shape: "circle", color: "orange" },
    { key: "A♭", numeral: "♭VI", number: "♭6", shape: "square", color: "blue" },
    {
      key: "B♭",
      numeral: "♭VII",
      number: "♭7",
      shape: "square",
      color: "purple",
    },
  ],
  locrian: [
    { key: "C", numeral: "i˚", number: "1", shape: "square", color: "red" },
    {
      key: "D♭",
      numeral: "♭II",
      number: "♭2",
      shape: "circle",
      color: "green-blue",
    },
    {
      key: "E♭",
      numeral: "♭iii",
      number: "♭3",
      shape: "circle",
      color: "blue-purple",
    },
    {
      key: "F",
      numeral: "iv",
      number: "4",
      shape: "circle",
      color: "purple-red",
    },
    { key: "G♭", numeral: "♭V", number: "♭5", shape: "square", color: "green" },
    { key: "A♭", numeral: "♭VI", number: "♭6", shape: "square", color: "blue" },
    {
      key: "B♭",
      numeral: "♭vii",
      number: "♭7",
      shape: "square",
      color: "purple",
    },
  ],
};

export interface ScaleDegree {
  key: string;
  numeral: string;
  number: string;
  color: ColorMusicColor;
  shape: "circle" | "square";
}
