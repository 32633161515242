import {
  Box,
  Container,
  Flex,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Navbar } from "./Navbar";
import { Sidebar } from "./Sidebar";
import { Header } from "./Header";
import { Content } from "./Content";
import { useState, useMemo, useEffect } from "react";
import {
  SavedChordProgression,
  SelectedSavedChordProgression,
} from "./Harmony";
import axios from "axios";
import { apiUrl } from "../Api";

function App() {
  const [selectedSavedChordProgression, setSelectedSavedChordProgression] =
    useState<SavedChordProgression | undefined>();

  const [reloadSavedChordProgressions, setReloadSavedChordProgressions] =
    useState<boolean>(false);

  const selectedSavedChordProgressionContext = useMemo(
    () => ({
      selectedSavedChordProgression,
      setSelectedSavedChordProgression,
      reloadSavedChordProgressions,
      setReloadSavedChordProgressions,
    }),
    [
      selectedSavedChordProgression,
      setSelectedSavedChordProgression,
      reloadSavedChordProgressions,
      setReloadSavedChordProgressions,
    ]
  );

  useEffect(() => {
    axios(apiUrl("ping"));
  });

  const isDesktop = useBreakpointValue({ base: false, lg: true });
  return (
    <Flex
      as="section"
      direction={{ base: "column", lg: "row" }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? <Sidebar /> : <Navbar />}
      <Box bg="bg-surface" pt={{ base: "0", lg: "3" }}>
        <Box
          bg="bg-canvas"
          borderTopLeftRadius={{ base: "none", lg: "2rem" }}
          height="full"
        >
          <VStack>
            <SelectedSavedChordProgression.Provider
              value={selectedSavedChordProgressionContext}
            >
              <Header />
              <Container py="4">
                <Content />
              </Container>
            </SelectedSavedChordProgression.Provider>
          </VStack>
        </Box>
      </Box>
    </Flex>
  );
}

export default App;
