import chromaticScale from "./../data/chromatic-scale.json";
import { ColorWheel, ColorWheelData, ColorWheelNode } from "./ColorWheel";
import * as Tone from "tone";
import { VStack, Heading } from "@chakra-ui/react";

// TODO figure out where to initialize this
export const Synth = new Tone.PolySynth(Tone.Synth).toDestination();

export const ChromaticScale = () => (
  <VStack>
    <Heading>Chromatic Scale</Heading>
    {/*
        <HStack>
          <svg width={100} height={100}>
            <g
              pointerEvents="none"
              textAnchor="middle"
              fontSize={28}
              fontFamily="sans-serif"
            >
              <ColorWheelNote
                data={{ color: "red", name: "C", shape: "circle" }}
              />
            </g>
          </svg>
          <svg width={100} height={100}>
            <ColorWheelNote
              data={{ color: "blue-purple", name: "F", shape: "circle" }}
            />
          </svg>
        </HStack>
           */}

    <ColorWheel
      size={800}
      data={chromaticScale as ColorWheelData}
      onSegmentClick={(node: ColorWheelNode) => {
        let note = node.data.name + "4";
        note = note.replace("♭", "b");
        console.log("segment click", { node, note });

        Synth.triggerAttackRelease(note, "8n");
      }}
    />
  </VStack>
);
