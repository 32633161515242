import _ from "lodash";
import {
  Box,
  Center,
  Heading,
  HStack,
  VStack,
  Text,
  IconButton,
  Flex,
  Spacer,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useColorModeValue,
  PopoverBody,
  CloseButton,
  Icon,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { CircleOfFifths } from "./CircleOfFifths";
import {
  useState,
  useEffect,
  useRef,
  useCallback,
  createContext,
  useContext,
} from "react";
import { Chord, Progression } from "./../Note";
import { Mode } from "../Mode";
import { FiInfo, FiPlayCircle, FiStopCircle } from "react-icons/fi";
import { MdOutlineDeleteForever, MdPlaylistAdd } from "react-icons/md";
import { motion } from "framer-motion";
import * as Tone from "tone";
import { DefaultBPM, HarmonyControls } from "./HarmonyControls";
import { ShapedNote } from "./ShapedNote";
import {
  DefaultInstrument,
  InstrumentConfig,
  InstrumentConfigs,
  InstrumentLibrary,
} from "../Instrument";
import * as Tonal from "@tonaljs/tonal";
import { ColorWheelData } from "./ColorWheel";

export const Harmony = () => {
  const [chords, setChords] = useState<Chord[]>([]);
  const [currentKey, setCurrentKey] = useState<string>("C");
  const [currentMode, setCurrentMode] = useState<Mode>("ionian");
  const [chordProgressionPlaying, setChordProgressionPlaying] =
    useState<boolean>(false);
  const [bpm, setBPM] = useState<number>(DefaultBPM);
  const [currentInstrumentConfig, setCurrentInstrumentConfig] =
    useState<InstrumentConfig>(InstrumentConfigs[DefaultInstrument]);
  const [keyAndModeTriads, setKeyAndModeTriads] = useState<string[]>(() => {
    // TODO remove `replace` workaround
    return Tonal.Mode.triads(currentMode, currentKey.replace("♭", "b"));
  });

  const toast = useToast();

  const {
    selectedSavedChordProgression,
    setSelectedSavedChordProgression,
    setReloadSavedChordProgressions,
  } = useContext(SelectedSavedChordProgression);

  useEffect(() => {
    if (selectedSavedChordProgression == null) {
      return;
    }

    console.log("new selected saved chord progression", {
      selectedSavedChordProgression,
    });
    // TODO update after local storage is updated to new progression and chord objects
    // setChords(selectedSavedChordProgression.progression.chords.values);
    setCurrentKey(selectedSavedChordProgression.keySignature);
    setCurrentMode(selectedSavedChordProgression.mode);
    if (selectedSavedChordProgression.instrumentConfig != null) {
      setCurrentInstrumentConfig(
        selectedSavedChordProgression.instrumentConfig
      );
    }

    setSelectedSavedChordProgression(undefined);
  }, [selectedSavedChordProgression]);

  const instrumentLibraryRef = useRef<InstrumentLibrary>(
    new InstrumentLibrary(DefaultInstrument)
  );
  const partRef = useRef<Tone.Part>();
  const samplerRef = useRef<Tone.Sampler>();

  const onKeySelect = useCallback(
    (key: string) => {
      setCurrentKey(key);
    },
    [currentKey]
  );
  const onModeSelect = useCallback(
    (mode: Mode) => {
      setCurrentMode(mode);
    },
    [currentMode]
  );
  const onBPMSelect = useCallback(
    (selectedBPM: number) => {
      setBPM(selectedBPM);
      // If playback is currently happening stop it and then start it again
      restartPlayback();
    },
    [bpm, chordProgressionPlaying] // Update this callback when playback starts or stops
  );
  const onInstrumentConfigUpdate = useCallback(
    (config: InstrumentConfig) => {
      console.log("updating instrument config", { config });
      setCurrentInstrumentConfig(config);
      setSamplerRef(config);
      restartPlayback();
    },
    [
      currentInstrumentConfig.kind,
      currentInstrumentConfig.octave,
      currentInstrumentConfig.release,
    ]
  );
  const addChord = useCallback(
    (chord: Chord) => {
      console.log("calling add chord", { chord });
      setChords((chords: Chord[]) => {
        return [...chords, chord];
      });

      if (!chordProgressionPlaying) {
        console.log("playing selected chord", { chord });
        const sampler = instrumentLibraryRef.current.samplerFor(
          currentInstrumentConfig.kind
        );

        const notesToPlay = chord.chord.notes.map(
          (n) =>
            n +
            (
              currentInstrumentConfig.octave ??
              currentInstrumentConfig.defaultOctave
            ).toString()
        );
        console.log({ notesToPlay });
        sampler.toDestination().triggerAttackRelease(notesToPlay, "4n");
      } else {
        restartPlayback();
      }
    },
    [chords.length, chordProgressionPlaying, currentInstrumentConfig]
  );

  const restartPlayback = () => {
    if (!chordProgressionPlaying) {
      return;
    }
    console.log("restarting playback");
    togglePlayback();
    togglePlayback();
  };

  const setSamplerRef = (samplerConfig?: InstrumentConfig) => {
    const config = samplerConfig ?? currentInstrumentConfig;
    const sampler = instrumentLibraryRef.current.samplerFor(config.kind);
    console.log("setting sampler to", { config });
    samplerRef.current = sampler.toDestination();
  };

  useEffect(() => {
    if (chordProgressionPlaying) {
      // Shouldn't happen since the play button wouldn't be visible but just in case...
      if (chords.length === 0) {
        return;
      }

      if (partRef.current !== undefined) {
        partRef.current.stop();
      }

      const bps = bpm / 60;

      const events = chords.map((chord: Chord, index: number) => {
        const octave =
          currentInstrumentConfig.octave ??
          currentInstrumentConfig.defaultOctave;
        const notesToPlay = chord.chord.notes.map((n) => n + octave.toString());
        return { time: index / bps, chord: notesToPlay };
      });

      partRef.current = new Tone.Part((time, value) => {
        if (samplerRef.current === undefined) {
          setSamplerRef();
        }

        console.log("playing", { value, time });
        (samplerRef.current as Tone.Sampler).triggerAttackRelease(
          value.chord,
          "8n",
          time
        );
      }, events).start(0);
      partRef.current.loop = true;
      partRef.current.loopStart = 0;
      partRef.current.loopEnd = chords.length / bps;

      Tone.Transport.start();
      console.log("music started", { chords });
    } else {
      console.log("music stopped");
      samplerRef.current?.releaseAll();
      Tone.Transport.stop();
      partRef.current?.stop();
    }
  }, [chordProgressionPlaying, currentInstrumentConfig, chords]);

  useEffect(() => {
    // TODO remove `replace` workaround
    setKeyAndModeTriads(
      Tonal.Mode.triads(currentMode, currentKey.replace("♭", "b"))
    );
  }, [currentKey, currentMode]);

  const togglePlayback = async () => {
    if (!chordProgressionPlaying) {
      await Tone.start();
      console.log("audio is ready");
    }
    setChordProgressionPlaying((v) => !v);
  };

  const chordRows = () => {
    const chordsPerRow = 4;
    const rows: Chord[][] = [];
    for (let i = 0; i < chords.length; i += chordsPerRow) {
      const row = chords.slice(i, i + chordsPerRow);
      rows.push(row);
    }
    console.log("chord rows", { rows, chords });
    return rows;
  };

  const saveChordProgression = () => {
    const progression = new Progression(chords);
    const savedChordProgression = {
      name: progression.toString(),
      romanNumbers: progression.romanNumerals(currentKey),
      progression: progression,
      mode: currentMode,
      keySignature: currentKey,
      instrumentConfig: currentInstrumentConfig,
      createdAt: new Date(),
    } as SavedChordProgression;
    storeChordProgression(savedChordProgression);
    setReloadSavedChordProgressions(true);

    console.log("saving chords", {
      chords,
      chordProgressionIndex: loadChordProgressions(),
    });

    toast({
      title: "Chord Progression Saved",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  console.log("KEY INFO", {
    triads: Tonal.Mode.triads(currentMode, currentKey),
  });

  return (
    <>
      <Center paddingBottom={10}>
        <Heading>Harmony</Heading>
      </Center>
      <Notification isVisible={false} />
      <HStack align="start">
        <CircleOfFifths
          tonic={currentKey}
          addChord={addChord}
          nodeProps={(data: ColorWheelData) => {
            const chord = new Chord(data.symbol);
            const isIncluded = Tonal.PcSet.isNoteIncludedIn(keyAndModeTriads);
            const included =
              isIncluded(chord.simpleName()) ||
              _.includes(keyAndModeTriads, chord.simpleName());

            const opacity = included ? 1 : 0.3;
            return { opacity };
          }}
        />
        <VStack p="4" align="start">
          <Box>
            <HarmonyControls
              onKeySelect={onKeySelect}
              onModeSelect={onModeSelect}
              onBPMSelect={onBPMSelect}
              onInstrumentConfigUpdate={onInstrumentConfigUpdate}
              keySignature={currentKey}
              mode={currentMode}
              instrumentConfig={currentInstrumentConfig}
            />
          </Box>
          <Box
            bg="bg-surface"
            borderRadius="lg"
            boxShadow={useColorModeValue("sm", "sm-dark")}
            maxW={{ lg: "3xl" }}
          >
            <Flex>
              <Box p="4">
                <Text fontSize="2xl" fontWeight="bold">
                  Chord Progression
                </Text>
              </Box>
              <Spacer />
              <Box p="4">
                <HStack>
                  <motion.div
                    animate={
                      chordProgressionPlaying
                        ? {
                            scale: [1, 1.05, 1.1, 1.15],
                            transition: {
                              repeat: Infinity,
                              repeatType: "mirror",
                            },
                          }
                        : {}
                    }
                  >
                    <IconButton
                      isDisabled={
                        chords.length == 0 && !chordProgressionPlaying
                      }
                      title="Play"
                      onClick={togglePlayback}
                      aria-label="Play"
                      icon={
                        chordProgressionPlaying ? (
                          <FiStopCircle />
                        ) : (
                          <FiPlayCircle />
                        )
                      }
                      fontSize="2em"
                      variant="ghost"
                    />
                  </motion.div>
                  <IconButton
                    isDisabled={chords.length == 0}
                    title="Save chord progression"
                    onClick={() => {
                      saveChordProgression();
                      console.log("chord progression saved");
                    }}
                    aria-label="Save"
                    icon={<MdPlaylistAdd />}
                    fontSize="2em"
                    variant="ghost"
                  />
                  <IconButton
                    isDisabled={chords.length == 0}
                    title="Reset chord progression"
                    onClick={() => {
                      setChords([]);
                      console.log("chord progression reset");
                    }}
                    aria-label="Reset"
                    icon={<MdOutlineDeleteForever />}
                    fontSize="2em"
                    variant="ghost"
                  />
                </HStack>
              </Box>
            </Flex>
            <VStack
              spacing="10"
              px={{ base: "4", md: "6" }}
              py={{ base: "5", md: "6" }}
              align="left"
            >
              {chordRows().map((row: Chord[], index: number) => {
                return (
                  <HStack
                    key={`chord-row-${index}`}
                    spacing="5"
                    direction={{ base: "column", md: "row" }}
                  >
                    {row.map((chord: Chord, j: number) => {
                      return (
                        <ShapedChord chord={chord} key={`${index}-${j}`} />
                      );
                    })}
                  </HStack>
                );
              })}
            </VStack>
          </Box>
        </VStack>
      </HStack>
    </>
  );
};

export const store = <T,>(key: LocalStorageKey, data: T) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const loadFromStorage = <T,>(key: LocalStorageKey) => {
  const value = localStorage.getItem(key);
  if (value == null) {
    return null;
  }
  return JSON.parse(value) as T;
};

export const storeChordProgression = (scp: SavedChordProgression) => {
  const index =
    loadFromStorage<ChordProgressionIndex>(STORED_CHORD_PROGRESSION_KEY) ?? {};
  index[scp.name] = scp;
  store(STORED_CHORD_PROGRESSION_KEY, index);
};

export const loadChordProgressions = () => {
  const index =
    loadFromStorage<ChordProgressionIndex>(STORED_CHORD_PROGRESSION_KEY) ?? {};

  // N.B. After loading the object graph from local storage we need to
  // reinstantiate every object that is a class to be able to access the
  // classes' methods.
  Object.values(index).forEach((entry) => {
    entry.progression = new Progression(
      entry.progression.chords.map((c) => new Chord(c.chord.name))
    );
    console.log("roman numerals", {
      rn: entry.romanNumbers.map((rn) => Tonal.RomanNumeral.get(rn)),
      entry,
    });
  });
  console.log({ index });
  return index;
};

export const SelectedSavedChordProgression = createContext<{
  selectedSavedChordProgression: SavedChordProgression | undefined;
  setSelectedSavedChordProgression: React.Dispatch<
    React.SetStateAction<SavedChordProgression | undefined>
  >;
  reloadSavedChordProgressions: boolean;
  setReloadSavedChordProgressions: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}>({
  selectedSavedChordProgression: undefined,
  setSelectedSavedChordProgression: () => {
    // do nothing
  },
  reloadSavedChordProgressions: false,
  setReloadSavedChordProgressions: () => {
    // do nothing
  },
});

type LocalStorageKey = "@colormusic:chord-progressions";
export const STORED_CHORD_PROGRESSION_KEY: LocalStorageKey =
  "@colormusic:chord-progressions";

export type ChordProgressionIndex = Record<string, SavedChordProgression>;
export interface SavedChordProgression {
  name: string;
  romanNumbers: string[];
  instrumentConfig: InstrumentConfig;
  keySignature: string;
  mode: Mode;
  progression: Progression;
  createdAt: Date;
  tags: string[];
}

export const Notification = ({ isVisible }: { isVisible: boolean }) => {
  return (
    <Box
      hidden={!isVisible}
      pt={{ base: "4", md: "8" }}
      pb={{ base: "12", md: "24" }}
      px={{ base: "4", md: "8" }}
    >
      <Flex direction="row-reverse">
        <Flex
          direction={{ base: "column", sm: "row" }}
          width={{ base: "full", sm: "md" }}
          boxShadow={useColorModeValue("md", "md-dark")}
          bg="bg-surface"
          borderRadius="lg"
          overflow="hidden"
        >
          <Center display={{ base: "none", sm: "flex" }} bg="bg-accent" px="5">
            <Icon as={FiInfo} boxSize="10" color="on-accent" />
          </Center>
          <Stack direction="row" p="4" spacing="3" flex="1">
            <Stack spacing="2.5" flex="1">
              <Stack spacing="1">
                <Text fontSize="sm" fontWeight="medium">
                  Updates Available
                </Text>
                <Text fontSize="sm" color="muted">
                  Hoorray. A new version is available.
                </Text>
              </Stack>
            </Stack>
            <CloseButton transform="translateY(-6px)" />
          </Stack>
        </Flex>
      </Flex>
    </Box>
  );
};

export const ShapedChord = ({
  chord,
  size = "lg",
}: {
  chord: Chord;
  size?: string;
}) => {
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Flex>
          <Box>
            <ShapedNote
              fontSize={size}
              note={chord.chord.tonic ?? chord.label()}
            />
          </Box>
        </Flex>
      </PopoverTrigger>
      <PopoverContent width="100%">
        <PopoverBody>
          <HStack>
            {chord.chord.name !== "" && (
              <VStack>
                <Center>{chord.chord.name}</Center>
                <HStack>
                  {chord.chord.notes.map((noteName: string, i: number) => {
                    return (
                      <ShapedNote key={`popover-note-${i}`} note={noteName} />
                    );
                  })}
                </HStack>
              </VStack>
            )}
            {chord.chord.name === "" &&
              chord.chord.notes.map((noteName: string, i: number) => {
                return (
                  <>
                    <Text as="em">Unidentified chord</Text>
                    <ShapedNote key={`popover-note-${i}`} note={noteName} />
                  </>
                );
              })}
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
